import React from "react";
import { Box, Typography } from "@mui/material";

import { CommentForm } from "./forms";

export const Comment = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F1F1F1",
        borderRadius: 1,
        minHeight: 100,
        m: 2,
      }}
    >
      <Box sx={{ m: 1 }}>
        <Box>
          <Typography variant="caption">
            <strong>Posted:</strong> {props.date}
          </Typography>
        </Box>
        <Box sx={{ mt: -1 }}>
          <Typography variant="caption">
            <strong>By:</strong> {props.author}
          </Typography>
        </Box>
      </Box>

      <Typography variant="body1" sx={{ ml: 2, mr: 2 }}>
        {props.message}
      </Typography>
    </Box>
  );
};

export const Comments = (props) => {
  return props.comments.map((c) => (
    <Comment message={c.Message} date={c.Date} author={c.Author} />
  ));
};

export const CreateNewComment = (props) => (
  <Box sx={{ m: 3 }}>
    <Typography variant="h6">{props.description}</Typography>
    <CommentForm handleClose={props.handleClose} postId={props.postId} />
  </Box>
);
