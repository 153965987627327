import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import { FaClinicMedical } from "react-icons/fa";
import "./gm-transition.css";
import GenericManufacturers from "../generic-manufacturers/generic-manufacturers";
import { getFromAirtable } from "../../../../components/utils";

function GmTransition(props) {
  const [nextClicked, setNextClicked] = useState(false);

  const [text, setText] = useState({});
  const fetchAllData = async () => {
    const res = await getFromAirtable("GM Transition Page Text", "Grid view", {
      data: { data: [], field: "All" },
    });
    // convert [{section, text}] --> section: text
    const t = res.data.data.reduce(
      (obj, item) => ({ ...obj, [item.Section]: item.Text }),
      {}
    );
    setText(t);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <div id="gm-transition">
      <>
        <div className="top">
          <div className="h3-top-holder">
            <h1>{text.title}</h1>
          </div>
        </div>
        <div className="bottom">
          <Row className="bottom-content">
            <Col className="legislation-info2">
              <h3>{text["left column title"]}</h3>
              <p>
                The legislation that is associated with applying for a
                complusory license in {props.countryName} is{" "}
                <strong>{props.selectedRecord["Legislation_name"]}</strong>.
                <br></br>
                <br></br>
                {text["left column content"]}
              </p>
            </Col>
            <div className="vl"></div>
            <Col className="legislation-info2">
              <h3>{text["right column title"]}</h3>
              <p>{text["right column content"]}</p>
            </Col>
          </Row>
          <h6 style={{ marginLeft: "7%", marginRight: "7%" }}>
            {text["bottom text"]}
          </h6>
          <div id="gen-man-btn">
            <Link
              className=""
              id="gnmf"
              activeClass="active"
              to="gm-transition-result"
              spy={true}
              smooth={true}
              offset={-50}
              duration={1000}
              onClick={() => {
                setNextClicked(true);
              }}
            >
              <div className="circle">
                <FaClinicMedical style={{ width: 55, fontSize: 45 }} />
              </div>
              <p>
                {text.button} {props.countryName}
              </p>
            </Link>
          </div>
        </div>
      </>
      <div id="gm-transition-result">
        {nextClicked && (
          <GenericManufacturers
            profileName={props.profileName}
            selectedRecord={props.selectedRecord}
            countryName={props.countryName}
            sectionNum={6}
            tripsCountries={props.tripsCountries}
            tripsOriginators={props.tripsOriginators}
            tripsConditions={props.tripsConditions}
          />
        )}
      </div>
    </div>
  );
}

export default GmTransition;
