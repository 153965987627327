import React from "react";
import { Vega } from "react-vega";
import { worldData } from "./world-data";
import { vegaLite } from "vega-embed";

function Map(props) {
  const spec = {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    width: 700,
    height: 300,
    data: {
      values: worldData,
      format: {
        type: "topojson",
        feature: "countries",
      },
    },
    params: [
      { name: "select", select: "point" },
      {
        name: "highlight",
        select: { type: "point", on: "mouseover" },
      },
    ],
    encoding: {
      color: {
        field: "count",
        type: "ordinal",
        scale: { range: ["lightgray", "#073763"] },
        sort: { field: "count", order: "ascending" },
      },
      strokeWidth: {
        condition: [
          {
            param: "select",
            empty: false,
            value: 2,
          },
          {
            param: "highlight",
            empty: false,
            value: 2,
          },
        ],
        value: 0,
      },
      stroke: {
        condition: [
          {
            param: "select",
            empty: false,
            value: "#6ac5dc",
          },
          {
            param: "highlight",
            empty: false,
            value: "#6ac5dc",
          },
        ],
        value: "gray",
      },
    },
    transform: props.chartTransform,
    projection: {
      type: "naturalEarth1",
      scale: 430,
      center: [16, 55],
    },
    mark: {
      type: "geoshape",
    },
  };
  const vgSpec = vegaLite.compile(spec).spec;
  if (!vgSpec.signals) {
    vgSpec.signals = [];
  }
  vgSpec.signals.push({
    name: "datumClick",
    on: [{ events: "click", update: "datum" }],
  });
  vgSpec.legends = [];

  function handleClick(_unused, datum) {
    // console.log("datum: ", datum);
    let check = props.onClick(datum);
    // console.log("check check: ", datum);
  }

  return (
    <Vega
      spec={vgSpec}
      signalListeners={{
        datumClick: handleClick,
      }}
    />
  );
}

export default Map;
