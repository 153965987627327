import React, { useState, useEffect } from "react";
import "./calendar.css";
function Calendar(props) {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  const [calendar, setCalendar] = useState({});
  function getData() {
    return new Promise((resolve, reject) => {
      let all = {};
      let events = [];
      base("Calendar Page Text")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              if (["title", "subtitle"].includes(record.fields["Section"])) {
                all[record.fields["Section"]] = record.fields["Title"];
              } else {
                let obj = {
                  title: record.fields["Title"],
                  description: record.fields["Description"],
                  link: record.fields["Link"],
                  // linkDescription: record.fields["Link Description"],
                };
                events.push(obj);
              }
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return reject({});
            }
            let result = {
              data: { ...all, events },
            };
            resolve(result);
          }
        );
    });
  }
  const fetchAllData = async () => {
    const result = await getData();
    console.log("res.data: ", result.data);
    setCalendar(result.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <div className="calendar-page">
      <div className="top">
        <div className="h3-top-holder">
          <h1>{calendar?.title}</h1>
        </div>
      </div>
      <div className="bottom">
        <h3>{calendar?.subtitle}</h3>
        <div className="calendar-section">
          <ul className="calendar-list">
            {calendar.events &&
              calendar.events.map((event, index) => (
                <li key={index}>
                  <div className="list-inline">
                    <a href={event.link}>
                      <strong style={{ color: "black" }}>{event?.title}</strong>
                      :{" "}
                    </a>
                    <p>{event?.description}</p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
