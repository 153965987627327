import React, { useState, useEffect } from "react";
import { FaArrowDown } from "react-icons/fa";
import { Link } from "react-scroll";
import "./current-research.css";

import SearchBar from "../../../../components/search-bar/search-bar";
import Feedback from "../feedback/feedback";
import Products from "../products/products";

import { Button } from "react-bootstrap";

import AirtableForm from "../../../../components/form/form";
import { Dialog, Typography, Button as MuiButton } from "@mui/material";

function CurrentResearch(props) {
  const [nextClicked, setNextClicked] = useState(false);

  // const columns = [
  //   { title: "ID", field: "Unique_ID", hozAlign: "left", width: 150 },
  //   { title: "Authors", field: "Title", hozAlign: "left", width: 150 },
  //   {
  //     title: "Year",
  //     field: "Year",
  //     hozAlign: "left",
  //     width: 150,
  //   },
  //   { title: "Link", field: "Link", hozAlign: "left", width: 150 },
  //   {
  //     title: "Reference",
  //     field: "Reference",
  //     hozAlign: "left",
  //     width: 150,
  //   },
  // ];

  // pull from airtable
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  function getData() {
    const data = [];
    const dataSet = new Set();
    return new Promise((resolve, reject) => {
      base("Library")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              if (record.fields["Reviewed"] === true) {
                data.push(record.fields);
                // dataSet.add(record.fields);
                // dataSet.add({ Title: record.fields.Title.slice(0, 1) });
              }
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return reject({});
            }
            let result = {
              data: data,
            };
            resolve(result);
          }
        );
    });
  }
  const fetchAllData = async () => {
    const res = await getData();
    const sorted = res.data.sort(function (a, b) {
      if (a.Title > b.Title) {
        return 1;
      }
      if (a.Title === b.Title) {
        return 0;
      }
      return -1;
    });
    console.log("SORTED: ", sorted);
    const newobj = {};
    for (const item of sorted) {
      if (Object.keys(newobj).indexOf(item.Title.slice(0, 1)) < 0) {
        newobj[item.Title.slice(0, 1)] = [item];
      } else {
        newobj[item.Title.slice(0, 1)].push(item);
      }
    }
    console.log("new OBj: ", newobj);
    setAllData(newobj);
    setAllDataLoaded(true);
  };
  useEffect(() => {
    fetchAllData();
    setAllDataLoaded(true);
  }, []);

  const [allData, setAllData] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  // sort this
  // want to only have each letter show up once
  function runSearch() {
    var input, filter, ul, li, i, txtValue;
    input = document.getElementById("search-input");
    filter = input.value.toUpperCase();

    // update alldata ?
    ul = document.getElementById("all-research");
    const h3 = ul.getElementsByClassName("letter-div");
    console.log("H3: ", h3);
    li = ul.getElementsByTagName("a");

    console.log("li check: ", li);

    for (i = 0; i < li.length; i++) {
      txtValue = li[i].innerText;
      console.log("see this: ", li[i].parentNode.getElementsByTagName("h3"));
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
      console.log(
        "what is this: ",
        li[i].parentNode.getElementsByTagName("a").length,
        li[i].parentNode.getElementsByTagName("a")[0],
        li[i].parentNode.getElementsByTagName("a")[0].style
      );
      const bools = [];
      for (
        let j = 0;
        j < li[i].parentNode.getElementsByTagName("a").length;
        j++
      ) {
        if (
          li[i].parentNode.getElementsByTagName("a")[j].style.display === "none"
        ) {
          bools.push(true);
        } else {
          bools.push(false);
        }
      }
      if (bools.every((v) => v === true)) {
        li[i].parentNode.getElementsByTagName("h3")[0].style.display = "none";
      } else {
        li[i].parentNode.getElementsByTagName("h3")[0].style.display = "";
      }
    }
    return null;
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formFields = [
    { label: "Authors", placeholder: "John Doe" },
    { label: "Title", placeholder: "Sample Paper" },
    { label: "Year", placeholder: "1999" },
    { label: "Link", placeholder: "www.google.com" },
  ];
  function addEmailToAirtable(authors, title, year, link) {
    base("Library").create(
      [
        {
          fields: {
            Authors: authors,
            Title: title,
            Year: year,
            Link: link,
            Reviewed: false,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          console.log(record.fields);
        });
      }
    );
  }
  const [productsClicked, setProductsClicked] = useState(false);

  return (
    <div id="knowledge-gaps">
      <div className="top">
        <div className="h3-top-holder">
          <h1>Current Research Landscape</h1>
        </div>
      </div>
      <div className="bottom">
        {allDataLoaded && (
          <>
            <SearchBar content={<h1></h1>} runSearch={runSearch} />
            <div className="sorted-research" id="all-research">
              {Object.keys(allData).map((key) => (
                <div className={key + " letter-div"}>
                  <h3>{key}</h3>
                  {allData[key].map((item) => (
                    <a href={item.Link}>
                      <p>
                        {item.Title}, <i>{item.Authors}</i>
                      </p>
                    </a>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}

        {!allDataLoaded && <h1>Loading Data...</h1>}

        <h5>
          Know of other existing research? Click{" "}
          <MuiButton onClick={handleClickOpen} variant="text">
            here
          </MuiButton>
          <Dialog
            open={open}
            onClose={handleClose}
            sx={{ minWidth: 900, minHeight: 700 }}
          >
            <div className="gm-dialog">
              <Typography variant="h4" sx={{ mt: 2, ml: 2 }}>
                Add a Resource
              </Typography>
              <div className="airtable-email-form">
                <div id="gm-form">
                  <AirtableForm formFields={formFields} />
                  <Button
                    onClick={() => {
                      const authors = document.getElementById("Authors").value;
                      const title = document.getElementById("Title").value;
                      const link = document.getElementById("Link").value;
                      let year = document.getElementById("Year").value;

                      addEmailToAirtable(authors, title, year, link);
                      // // hide the form
                      // document.getElementById("gm-form").style.display = "none";
                      // // show the thank you screen
                      // document.getElementById("form-submitted").style.display =
                      //   "block";
                      handleClose();
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div id="form-submitted">
                  <p>Thank you for submitting the form!</p>
                  <p>
                    Know other resources? Click here to fill out the form again!
                  </p>
                  <Button
                    onClick={() => {
                      // hide the thank you
                      document.getElementById("form-submitted").style.display =
                        "none";
                      // show the form
                      document.getElementById("gm-form").style.display =
                        "block";
                    }}
                  >
                    Redo
                  </Button>
                </div>
              </div>

              <Button onClick={handleClose}>Close</Button>
            </div>
          </Dialog>{" "}
          to tell us about it.
        </h5>
        <div id="gen-man-btn">
          <Link
            className=""
            id="gnmf"
            activeClass="active"
            to="current-research-result"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            onClick={() => {
              setNextClicked(true);
            }}
          >
            <div className="circle">
              <FaArrowDown style={{ width: 55, fontSize: 45 }} />
            </div>
            <p>Continue to join the community or </p>
          </Link>
          <p>
            Click{" "}
            <MuiButton id="altButtonHover">
              <Link
                className=""
                id="gnmf"
                activeClass="active"
                to="current-research-result"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                onClick={() => {
                  setProductsClicked(true);
                }}
              >
                here
              </Link>
            </MuiButton>{" "}
            to explore trips flexibilities by product and country
          </p>
        </div>

        {/* <div id="gen-man-btn">
          <Link
            className=""
            id="gnmf"
            activeClass="active"
            to="current-research-result"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            onClick={() => {
              setNextClicked(true);
            }}
          >
            <div className="circle">
              <FaArrowDown style={{ width: 55, fontSize: 45 }} />
            </div>
            <p>Continue to join the community</p>
          </Link>
        </div> */}
      </div>
      <div id="current-research-result">
        {nextClicked && <Feedback />}
        {productsClicked && <Products profileName="researcher" />}
      </div>
    </div>
  );
}

export default CurrentResearch;
