import * as React from "react";
import { Box, TextField } from "@mui/material";

export default function AirtableForm(props) {
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "550px" },
      }}
      noValidate
      autoComplete="off"
    >
      {props.formFields.map((field, index) => (
        <div>
          <TextField
            multiline={field.rows ? true : false}
            defaultValue={field.defaultValue}
            id={field.label}
            key={index}
            margin="normal"
            variant="outlined"
            label={field.label}
            placeholder={field.placeholder || " "}
            rows={field.rows || 2}
          />
        </div>
      ))}
      {/* <Button onClick={(e) => {}}>Submit</Button> */}
    </Box>
  );
}
