import React, { useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";
import "./profile-select.css";

import Products from "../products/products";
import { CgFileDocument } from "react-icons/cg";
import { GoLaw } from "react-icons/go";
import { TiGroup } from "react-icons/ti";
import { FaGraduationCap } from "react-icons/fa";
import { Link } from "react-scroll";
import CurrentResearch from "../current-research/current-research";
import { getFromAirtable } from "../../../../components/utils";

function ProfileSelect(props) {
  const [profile, setProfile] = useState("");
  const [profileSelected, setProfileSelected] = useState(false);

  const [text, setText] = useState({});
  const fetchAllData = async () => {
    const res = await getFromAirtable("Profile Page Text", "Grid view", {
      data: { data: [], field: "All" },
    });
    // convert [{section, text}] --> section: text
    const t = res.data.data.reduce(
      (obj, item) => ({ ...obj, [item.Section]: item.Text }),
      {}
    );
    setText(t);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  function ProfileButton(props) {
    return (
      <Link
        to="profile-result"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-50}
        duration={1000}
        onClick={(e) => {
          setProfile(props.buttonLabel);
          setProfileSelected(true);
        }}
      >
        <div className="circle">
          <div className="circle-content">
            <p>{props.buttonLabel}</p>
            {props.buttonIcon}
          </div>
        </div>
      </Link>
    );
  }

  const ProfileResult = () => (
    <div>
      {setProfile && (
        <div id="profile-result">
          {(profile === "Lawmaker" ||
            profile === "Policymaker" ||
            profile === "Advocate") && (
            <Products profileName={profile} sectionNum={3} />
          )}
          {profile === "Researcher" && (
            <CurrentResearch profileName={profile} sectionNum={3} />
          )}
        </div>
      )}
      {null}
    </div>
  );

  return (
    <div>
      <div className="top">
        <div className="h3-top-holder">
          <h1>{text.title}</h1>
          {/* <CgProfile className="header-icon" /> */}
        </div>
      </div>
      <div className="bottom">
        <h2>{text["section 1"]}</h2>
        <Row className="main-button-holder">
          <Col>
            <ProfileButton
              buttonLabel="Lawmaker"
              buttonIcon={<GoLaw style={{ fontSize: 40 }} />}
            />
          </Col>
          <Col>
            <ProfileButton
              buttonLabel="Policymaker"
              buttonIcon={<CgFileDocument style={{ fontSize: 40 }} />}
            />
          </Col>
          <Col>
            <ProfileButton
              buttonLabel="Advocate"
              buttonIcon={<TiGroup style={{ fontSize: 40 }} />}
            />
          </Col>
          <Col>
            <ProfileButton
              buttonLabel="Researcher"
              buttonIcon={<FaGraduationCap style={{ fontSize: 40 }} />}
            />
          </Col>
        </Row>
      </div>

      <ProfileResult />
    </div>
  );
}

export default ProfileSelect;
