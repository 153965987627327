import React, { useState } from "react";
import { Link } from "react-scroll";
import "./pressure-points.css";
import { HiOutlineMail } from "react-icons/hi";
import EmailCollection from "../email-collect/email-collect";
import { Col, Row } from "react-bootstrap";

// TODO:
function PressurePoints(props) {
  const [nextClicked, setNextClicked] = useState(false);
  const [multiRecord, setMultiRecord] = useState([]);
  let points = [];
  if (props.selectedRecord.Pressure_Points) {
    points = props.selectedRecord.Pressure_Points.split(", ");
  }

  return (
    <div id="pressure-points">
      <div>
        <div className="top">
          <div className="h3-top-holder">
            <h1>Pressure Points</h1>
          </div>
        </div>
        <div className="bottom">
          <p>
            Now that you know whether or not there is an existing legal basis
            for the use of compulsory licenses, it is useful to understand where
            to use this information for advocacy purposes.{" "}
          </p>
          <Row>
            <Col className="left-col">
              <p className="pressure-points-header">
                In {props.countryName}, the Ministries responsible for these
                issues are:
              </p>
              <div className="center-list">
                <ul>
                  {points.slice(0, 2).map((point) => (
                    <li>{point}</li>
                  ))}
                </ul>
              </div>
            </Col>
            <div className="vl"></div>
            <Col>
              <p className="pressure-points-header">
                For a regional/global approach:
              </p>
              <div className="center-list">
                <ul>
                  {points.slice(2, points.length).map((point) => (
                    <li>{point}</li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>

          <p>
            See also the resources tab for more information and useful
            evidence/tools for advocacy.
          </p>
          <Link
            className=""
            id="to-email-collection"
            activeClass="active"
            to="pressure-points-result"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            onClick={() => {
              setNextClicked(true);
            }}
          >
            <div className="circle">
              <HiOutlineMail style={{ width: 55, fontSize: 45 }} />
            </div>
            <p>One more step before receiving your action plan!</p>
          </Link>
        </div>
      </div>

      <div id="pressure-points-result">
        {nextClicked && (
          <EmailCollection
            profileName={props.profileName}
            countryName={props.countryName}
            selectedRecord={props.selectedRecord}
            tripsCountries={props.tripsCountries}
            tripsOriginators={props.tripsOriginators}
            tripsConditions={props.tripsConditions}
          />
        )}
      </div>
    </div>
  );
}

export default PressurePoints;
