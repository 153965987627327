import * as React from "react";
import { BiSearch } from "react-icons/bi";

export default function SearchBar(props) {
  function runSearch() {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-input");
    filter = input.value.toUpperCase();

    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName("li");

    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
  return (
    <div className="search-bar-holder">
      <BiSearch />
      <input
        type="text"
        id="search-input"
        onKeyUp={props.runSearch}
        placeholder={"Search research library..."}
        title="Start typing"
      />
    </div>
  );
}
