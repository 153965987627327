import React, { useState, useEffect } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button, Divider, Grid, Typography, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import { Posts, CreateNewPost } from "./post";
import { getPostsData, getTextData } from "./data";
import "./discussion.css";

function Discussion(props) {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  const [text, setText] = useState(null);
  const [posts, setPosts] = useState([]);

  const fetchAllData = async () => {
    const textData = await getTextData(base);
    const postsData = await getPostsData(base);
    setText(Object.fromEntries(textData.data));
    setPosts(postsData.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {text && (
        <div>
          <div className="top">
            <div className="h3-top-holder">
              <h1>{text.title}</h1>
            </div>
          </div>

          <div className="bottom">
            <div className="discussion-section">
              <Grid container sx={{ position: "relative" }}>
                <Grid item sm={10} md={10} xs={10}>
                  <Typography variant="h6">{text.subtitle}</Typography>
                </Grid>
                <Grid
                  item
                  sm={2}
                  md={2}
                  xs={12}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <Button
                    class="add-post-button"
                    variant="contained"
                    onClick={() => setIsOpen(true)}
                    endIcon={<AddBoxIcon />}
                    size="small"
                  >
                    <Typography variant="body">Create New Post</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                sx={{ maxWidth: 2000, maxHeight: 2000 }}
              >
                <CreateNewPost
                  handleClose={() => setIsOpen(false)}
                  description={text.postFormDescription}
                />
              </Dialog>
              <Divider light sx={{ mb: 2, mt: 5, color: "lightgray" }} />

              <Box
                sx={{
                  backgroundColor: "lightgray",
                  borderRadius: 1,
                  p: 5,
                }}
              >
                {posts.length >= 1 && (
                  <Posts
                    posts={posts}
                    description={text.commentFormDescription}
                  />
                )}
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Discussion;
