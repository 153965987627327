import React, { useState, useEffect } from "react";
import "./glossary.css";
function Glossary(props) {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  const [glossary, setGlossary] = useState([]);
  function getData() {
    return new Promise((resolve, reject) => {
      let abtxt = [];
      base("Glossary Page Text")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              let obj = {
                title: record.fields["Text"],
                description: record.fields["Description"],
                link: record.fields["URL"],
              };
              abtxt.push(obj);
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return reject({});
            }
            let result = {
              data: abtxt,
            };
            resolve(result);
          }
        );
    });
  }
  const fetchAllData = async () => {
    const res = await getData();
    setGlossary(res.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <div className="glossary-page">
      <div className="top">
        <div className="h3-top-holder">
          <h1>TRIPS Glossary</h1>
        </div>
      </div>
      <div className="bottom">
        <h2>Your Place for everything TRIPS/compulsory license related</h2>
        <div className="glossary-section">
          <h3>GLOSSARY</h3>
          <ol className="glossary-list">
            {glossary.map((el, index) => (
              <li key={index}>
                <div className="list-inline">
                  <strong>{el.title}</strong>:{" "}
                  <p>
                    {el.description}
                    <a href={el.link}>[{index + 1}]</a>
                  </p>
                </div>
              </li>
            ))}
          </ol>
        </div>
        {/* <hr></hr>
        <div className="references-section">
          <h3>REFERENCES</h3>
          <ol>
            <li>
              UNHLP Report of the United Nations Secretary General’s on Access
              to medicines. Promoting Innovation and access to health
              technologies. September 2016 pp. 5
            </li>
            <li>
              t’Hoen, E. Private patents and public health. Changing
              Intellectual Property rules for access to medicines Health Action
              International (HAI) 2016 pp. 146
            </li>
            <li>ibid</li>
            <li>ibid</li>
            <li>
              t’Hoen, E. Private Patents and Public Health. Changing
              Intellectual Property rules for access to medicines Health Action
              International (HAI) 2016 pp
            </li>
            <li>ibid</li>
            <li>
              MSF ACCESS CAMPAIGN Briefing note on supplementary protection
              certificates Threatening access to affordable medicines across the
              European Union September 2017. PP. 1 Accessible at
              https://www.msfaccess.org/content/briefing-note-supplementary-protection-certificates-spcs
            </li>
            <li>
              UNHLP Report of the United Nations Secretary General’s on Access
              to medicines. Promoting Innovation and access to health
              technologies. September 2016 pp.6
            </li>
            <li>ibid</li>
          </ol>
        </div> */}
      </div>
    </div>
  );
}

export default Glossary;
