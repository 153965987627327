import React from "react";
import { VscDebugRestart } from "react-icons/vsc";
import "./action-plan.css";
import { Link } from "react-scroll";

import ReactPDF, {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import ReactMarkdown from "react-markdown";

function ActionPlan(props) {
  const glossaryData = [
    {
      term: "Bolar Exemption",
      meaning:
        "A legal exception that permits the use of a patented invention before the patent expires for the purposes of obtaining marketing approval of a generic product for a commercialisation once the patent expires.",
    },
    {
      term: "Compulsory License/Government Use",
      meaning:
        "A compulsory licence is an authorisation by a competent government authority to use a patented invention by a third party without the consent of the patent holder, against a payment of “adequate remuneration”. A “government use” is a particular form of compulsory licence issued by the government for its own use or for the use of a third party.",
    },
    {
      term: "Data Exclusivity",
      meaning:
        "Prohibition of use of pharmaceutical test data submitted to a regulatory agency by an originator company for the purpose of registering a generic drug. Generic companies rely on this test data to demonstrate the safety and efficacy of their bioequivalent drug.",
    },
    {
      term: "Evergreening",
      meaning:
        "The practice of seeking secondary patents with the aim to extend market exclusivity beyond the patent term of the basic patent.",
    },
    {
      term: "Most Favoured Nation (MFN) treatment",
      meaning:
        "One of the founding principles of the World Trade Organization. MFN Treatment says that all trading partners must be given equal advantages as the “most favoured” trading partner. For example, a country may not grant tariff exemptions to only one trading partner unless it extends the same to all trading partners.",
    },
    {
      term: "Parallel Importation",
      meaning:
        "Parallel importation refers to the import and resale in a country, without the consent of the patent holder, of a patented product that has been legitimately put on the market of the exporting country. Parallel imports take place when there are significant price differences for the same good in different markets.",
    },
    {
      term: "Supplementary Protection Certificate",
      meaning:
        "Supplementary Protection Certificates (SPCs) constitute an example of additional monopoly rights, intended to expand monopoly protection for medicines beyond the twenty-year patent term.",
    },
    {
      term: "TRIPS Flexibilities",
      meaning:
        "A term used broadly to describe a set of norms, rules and standards that allow variations in the implementation of the TRIPS Agreement obligations, including limits on the excessive use of Intellectual Property Rights.",
    },
    {
      term: "TRIPS+",
      meaning:
        "These are measures that require more stringent intellectual property standards than those contained in TRIPS, or that limit flexibilities inherent in TRIPS. They are often contained in bilateral or regional trade agreements.",
    },
    {
      term: "Voluntary Licence",
      meaning:
        "A license granted by a patent holder to a third party to produce and/or market and distribute a patented product, usually in exchange for a royalty on net sales and certain other conditions (for example geographical restrictions on where the product can be sold).",
    },
  ];
  console.log("props check: ", props, props.selectedRecord);
  const titleFontSize = 28;
  const subTitleFontSize = 22;
  const textFontSize = 16;

  const styles = StyleSheet.create({
    title: {
      margin: 20,
      marginBottom: 10,
      fontSize: 25,
      textAlign: "center",
      backgroundColor: "#073763",
      color: "white",
      textTransform: "uppercase",
      fontFamily: "Helvetica",
    },
    subtitle: {
      marginTop: 20,
      marginLeft: 20,
      marginRight: 20,
      fontSize: 18,
      textAlign: "center",
      backgroundColor: "#073763",
      color: "white",
      fontFamily: "Helvetica",
    },
    normal: {
      marginLeft: 20,
      marginRight: 20,
      fontSize: 12,
      paddingTop: 5,
      fontFamily: "Times-Roman",
    },
    regular: {
      marginLeft: 20,
      marginRight: 20,
      fontSize: 12,
      paddingTop: 5,
      fontFamily: "Times-Italic",
    },
    footer: {
      position: "absolute",
      fontSize: 8,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "gray",
    },
    footerBottom: {
      position: "absolute",
      fontSize: 8,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "gray",
    },
  });

  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });

  function MyDocument() {
    console.log("props.selected record in action plan: ", props.selectedRecord);
    const LicenseGrounds = () => {
      return (
        <Text style={styles.regular}>
          <ReactMarkdown>
            {props.selectedRecord["License_grounds"]}
          </ReactMarkdown>
        </Text>
      );
    };
    if (props.profileName === "Advocate") {
      return (
        <Document>
          <Page size="A4">
            <Text style={styles.title}>
              Action Plan for {props.profileName}s in {props.countryName}
            </Text>
            <Text style={styles.subtitle}>
              Past Use of Compulsory Licenses in {props.countryName}
            </Text>
            {props.selectedRecord["Past_use"] === "false" && (
              <Text style={styles.normal}>
                Compulsory Licenses have not yet been used in the past in{" "}
                <Text style={styles.regular}>{props.countryName}</Text>.
                However, they have been used in the EU in the past including
                Germany, Italy, and Romania. In these countries, compulsory
                licenses have been used for drugs for both communicable and
                non-communicable diseases, namely:{" "}
                <Text style={styles.regular}>{props.tripsConditions}</Text>.
              </Text>
            )}
            {props.selectedRecord["Past_use"] === "true" && (
              <View>
                <Text style={styles.normal}>
                  Compulsory Licenses have been used before in{" "}
                  <Text style={styles.regular}>{props.countryName}</Text>
                </Text>
                <Text style={styles.normal}>
                  There is 1 instance of compulsory license use in{" "}
                  <Text style={styles.regular}>{props.countryName}</Text>! The
                  type of flexibility used in this instance was{" "}
                  <Text style={styles.regular}>
                    {props.selectedRecord["Flexibility_type"]}
                  </Text>
                  . TRIPS flexibilities have been with patents regarding the
                  drug{" "}
                  <Text style={styles.regular}>
                    {props.selectedRecord["Product"]}
                  </Text>{" "}
                  to treat{" "}
                  <Text style={styles.regular}>
                    {props.selectedRecord["Disease"]}
                  </Text>
                  . The Originator involved was
                  <Text style={styles.regular}>
                    {props.selectedRecord["Generic_manufacturer_name"]}.
                  </Text>
                </Text>
              </View>
            )}
            <Text style={styles.subtitle}>
              What is the legal basis for obtaining a compulsory license in{" "}
              {props.countryName}?
            </Text>
            <Text style={styles.normal}>
              The legislation that regulates compulsory licenses in{" "}
              <Text style={styles.regular}>{props.countryName}</Text> is{" "}
              <Text style={styles.regular}>
                {props.selectedRecord["Legislation_name"]}
              </Text>
            </Text>
            <Text style={styles.normal}>
              <LicenseGrounds />
              You can find more information about the legal basis and the
              grounds for applying for a license in{" "}
              <Text style={styles.regular}>{props.countryName}</Text> as well as
              latest amendments
              <a
                href={props.selectedRecord["Legislation_link"]}
                target="_blank"
              >
                here.
              </a>
            </Text>
            <Text style={styles.subtitle}>
              Pressure Points for Action in{" "}
              <Text style={styles.regular}>{props.countryName}</Text>
            </Text>
            {/* NEED to update this with airtable when they finish filling out the table */}
            <Text style={styles.normal}>
              In <Text style={styles.regular}>{props.countryName}</Text>, the
              Ministries responsible for these issues are:
              <Text style={styles.regular}>
                <ul style={styles.list}>
                  <li>Ministry of Healths</li>
                  <li>Ministry of Economic Affair</li>
                </ul>
              </Text>
            </Text>
            <Text style={styles.normal}>For a regional/global approach:</Text>
            <Text style={styles.regular}>
              Permanent Representative to the European Union, Brussels
            </Text>
            <Text style={styles.regular}>
              Delegation to World Health Organization, Geneva
            </Text>
            <Text style={styles.regular}>World Trade Organization, Geneva</Text>
            <Text style={styles.normal}>
              See also the resources tab for more information and useful
              evidence/tools for advocacy.
            </Text>
            <Text style={styles.normal}>
              Now that you know whether or not there is an existing legal basis
              for the use of compulsory licenses, it is useful to understand
              where to use this information for advocacy purposes.
            </Text>

            <Text style={styles.subtitle}>Call to Action</Text>
            <Text style={styles.normal}>Take Action now!</Text>
            <Text style={styles.normal}>
              Compulsory licensing and other health- oriented IP-related tools
              offer governments the opportunity to protect public health where
              patents are an obstacle to doing so in an efficient and effective
              way. They can also be used to guarantee fair competition and
              counter market abuses. They are{" "}
              <strong>legal, legitimate and widely used</strong> have been
              effectively used around the world in the past. The information on
              this website will provide a basis for policymakers and lawmakers
              as they endeavour to make best use of these tools in national
              settings where access to new and patented medicines is putting
              strains on health systems.{" "}
            </Text>
            <Text style={styles.normal}>
              Find other resources to support the effective use of TRIPS
              Flexibilities here:
              <a href="Find other resources to support the effective use of TRIPS Flexibilities here: https://haiweb.org/what-we-do/free-trade/using-flexibilities-to-advance-access-to-medicines/ ">
                https://haiweb.org/what-we-do/free-trade/using-flexibilities-to-advance-access-to-medicines/
              </a>
            </Text>
            <Text style={styles.normal}>
              IMPORTANT TO NOTE: Data exclusivity (de facto extension of patent
              term protection) legislation associated with rare diseases, orphan
              medicines as well as paediatric formulations pose a{" "}
              <a href="https://pubmed.ncbi.nlm.nih.gov/28670455/">
                substantive obstacle
              </a>{" "}
              to the use/exercise of TRIPS flexibilities, notably compulsory
              licenses. Data exclusivity prevents generic manufacturers
              accessing innovator company information which they need to both
              develop and obtain marketing approval of generic copies. Data
              exclusivity also blocks the so-called ‘Bolar clause’ whereby
              pharmaceutical companies must make clinical trial and test data
              available to generic manufacturers twelve months before the
              expiration of a patent. The Bolar clause is intended to accelerate
              generic manufacturing, because it means a generic manufacturers
              can start work while a patent is still in force, and the generic
              can be ready for the market as soon as the patent expires. As part
              of the{" "}
              <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:52020DC0761&rid=3">
                discussion of the European Pharmaceutical Strategy
              </a>{" "}
              data exclusivity and others provisions are currently under review.
            </Text>
            <Text style={styles.footer} fixed>
              Contact: info@haiweb.org
            </Text>
            <Text style={styles.footerBottom} fixed>
              <a href="https://haiweb.org/">https://haiweb.org/</a>
            </Text>
          </Page>

          <Page size="A4">
            <Text style={styles.title}>GLOSSARY</Text>
            <Text style={styles.normal}>
              1. Bolar Exemption: A legal exception that permits the use of a
              patented invention before the patent expires for the purposes of
              obtaining marketing approval of a generic product for a
              commercialisation once the patent expires.
              <a href="https://www.google.com/url?q=https://haiweb.org/what-we-do/free-trade/making-sense-terminology/%23_ftnref1&sa=D&source=editors&ust=1628543262994990&usg=AOvVaw0A3v1nKgXHuRxVRiYKCSyv">
                [1]
              </a>
            </Text>
            <Text style={styles.normal}>
              2. Compulsory License/Government Use: A compulsory licence is an
              authorisation by a competent government authority to use a
              patented invention by a third party without the consent of the
              patent holder, against a payment of “adequate remuneration”. A
              “government use” is a particular form of compulsory licence issued
              by the government for its own use or for the use of a third party.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref2">
                [2]
              </a>
            </Text>
            <Text style={styles.normal}>
              Data Exclusivity: Prohibition of use of pharmaceutical test data
              submitted to a regulatory agency by an originator company for the
              purpose of registering a generic drug. Generic companies rely on
              this test data to demonstrate the safety and efficacy of their
              bioequivalent drug.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref3">
                [3]
              </a>
            </Text>
            <Text style={styles.normal}>
              4. Evergreening: The practice of seeking secondary patents with
              the aim to extend market exclusivity beyond the patent term of the
              basic patent.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref4">
                [4]
              </a>
            </Text>
            <Text style={styles.normal}>
              5. Most Favoured Nation (MFN) treatment: One of the founding
              principles of the World Trade Organization. MFN Treatment says
              that all trading partners must be given equal advantages as the
              “most favoured” trading partner. For example, a country may not
              grant tariff exemptions to only one trading partner unless it
              extends the same to all trading partners. [5]
            </Text>
            <Text style={styles.normal}>
              6. Parallel Importation: Parallel importation refers to the import
              and resale in a country, without the consent of the patent holder,
              of a patented product that has been legitimately put on the market
              of the exporting country. Parallel imports take place when there
              are significant price differences for the same good in different
              markets.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref6">
                [6]
              </a>
            </Text>
            <Text style={styles.normal}>
              7. Supplementary Protection Certificate: Supplementary Protection
              Certificates (SPCs) constitute an example of additional monopoly
              rights, intended to expand monopoly protection for medicines
              beyond the twenty-year patent term.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref7">
                [7]
              </a>
            </Text>
            <Text style={styles.normal}>
              8. TRIPS Flexibilities: A term used broadly to describe a set of
              norms, rules and standards that allow variations in the
              implementation of the TRIPS Agreement obligations, including
              limits on the excessive use of Intellectual Property Rights.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref8">
                [8]
              </a>
            </Text>
            <Text style={styles.normal}>
              9. TRIPS+: These are measures that require more stringent
              intellectual property standards than those contained in TRIPS, or
              that limit flexibilities inherent in TRIPS. They are often
              contained in bilateral or regional trade agreements.{" "}
            </Text>
            <Text style={styles.normal}>
              10. Voluntary Licence: A license granted by a patent holder to a
              third party to produce and/or market and distribute a patented
              product, usually in exchange for a royalty on net sales and
              certain other conditions (for example geographical restrictions on
              where the product can be sold){" "}
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref9">
                [10]
              </a>
            </Text>
            <Text style={styles.footer} fixed>
              Contact: info@haiweb.org
            </Text>
            <Text style={styles.footerBottom} fixed>
              <a href="https://haiweb.org/">https://haiweb.org/</a>
            </Text>
          </Page>
          <Page size="A4">
            <Text style={styles.title}>Resources</Text>
            <Text style={styles.normal}>
              <a href="https://haiweb.org/what-we-do/free-trade/heatmap-of-trips-flexibilities-in-the-eu/">
                - HAI Heatmap
              </a>
            </Text>
            <Text style={styles.normal}>
              <a href="https://haiweb.org/wp-content/uploads/2019/06/HAI-TRIPS-Brochure-1.pdf">
                - TRIPS Brochure
              </a>
            </Text>
            <Text style={styles.normal}>
              <a href="https://e-courses.epo.org/course/view.php?id=160 ">
                - EPO Compulsory License Resource
              </a>
            </Text>
            <Text style={styles.normal}>
              <a href="https://www.medicinesforeurope.com/biosimilar-medicines/who-we-are/#section-4">
                - Medicines for Europe
              </a>
            </Text>
            <Text style={styles.footer} fixed>
              Contact: info@haiweb.org
            </Text>
            <Text style={styles.footerBottom} fixed>
              <a href="https://haiweb.org/">https://haiweb.org/</a>
            </Text>
          </Page>
        </Document>
      );
    } else {
      return (
        <Document>
          <Page size="A4">
            <Text style={styles.title}>
              Action Plan for {props.profileName}s in {props.countryName}
            </Text>
            <Text style={styles.subtitle}>
              Past Use of Compulsory Licenses in {props.countryName}
            </Text>
            {props.selectedRecord["Past_use"] === "false" && (
              <Text style={styles.regular}>
                Compulsory Licenses have not yet been used in the past in{" "}
                <Text style={styles.regular}>{props.countryName}</Text>.
                However, they have been used in the EU in the past including
                Germany, Italy, and Romania. In these countries, compulsory
                licenses have been used for drugs for both communicable and
                non-communicable diseases, namely:{" "}
                <Text style={styles.regular}>{props.tripsConditions}</Text>.
              </Text>
            )}
            {props.selectedRecord["Past_use"] === "true" && (
              <View>
                <Text style={styles.normal}>
                  Compulsory Licenses have been used before in{" "}
                  <Text style={styles.regular}>{props.countryName}</Text>
                </Text>
                <Text style={styles.normal}>
                  There is 1 instance of compulsory license use in{" "}
                  <Text style={styles.regular}>{props.countryName}</Text>! The
                  type of flexibility used in this instance was{" "}
                  <Text style={styles.regular}>
                    {props.selectedRecord["Flexibility_type"]}
                  </Text>
                  . TRIPS flexibilities have been with patents regarding the
                  drug{" "}
                  <Text style={styles.regular}>
                    {props.selectedRecord["Product"]}
                  </Text>{" "}
                  to treat{" "}
                  <Text style={styles.regular}>
                    {props.selectedRecord["Disease"]}
                  </Text>
                  . The Originator involved was{" "}
                  <Text style={styles.regular}>
                    {props.selectedRecord["Generic_manufacturer_name"]}
                  </Text>
                  .
                </Text>
              </View>
            )}
            <Text style={styles.subtitle}>
              What is the legal basis for obtaining a compulsory license in{" "}
              {props.countryName}?
            </Text>
            <Text style={styles.normal}>
              The legislation that regulates compulsory licenses in{" "}
              <Text style={styles.regular}>{props.countryName}</Text> is{" "}
              <Text style={styles.regular}>
                {props.selectedRecord["Legislation_name"]}
              </Text>
            </Text>
            <Text style={styles.normal}>
              <LicenseGrounds />
              You can find more information about the legal basis and the
              grounds for applying for a license in{" "}
              <Text style={styles.regular}>{props.countryName}</Text> as well as
              latest amendments
              <a
                href={props.selectedRecord["Legislation_link"]}
                target="_blank"
              >
                here.
              </a>
            </Text>
            <Text style={styles.subtitle}>
              Who are the generic manufacturers in {props.countryName}?
            </Text>
            {typeof props.selectedRecord["Generic_manufacturer_name"] !==
              "undefined" && (
              <>
                <Text style={styles.normal}>
                  An effective exercise of TRIPS flexibilities and successful
                  use of a Compulsory Licensing relies on two elements: on one
                  hand, a legal and regulatory framework (as above) that
                  supports government actions to unilaterally terminate the
                  rights of a patent holder, and on the other the existence
                  of/access to the technical capabilities and internationally
                  certified know-how and expertise to exploit such rights in a
                  productive and timely manner. For the latter, it is critical
                  to maintain an updated database of Good Manufacturing
                  Practices (GMP)-compliant generic and biosimilar
                  pharmaceutical companies in your country.
                </Text>
                <Text style={styles.normal}>
                  The generic manufacturer for {props.countryName} is{" "}
                  <Text style={styles.regular}>
                    {props.selectedRecord["Generic_manufacturer_name"]}
                  </Text>
                  . You can learn more about{" "}
                  {props.selectedRecord["Generic_manufacturer_name"]}'s work in{" "}
                  {props.countryName}{" "}
                  <a
                    href={props.selectedRecord["Generic_manufacturer_link"]}
                    target="_blank"
                  >
                    here.
                  </a>
                </Text>
              </>
            )}
            {typeof props.selectedRecord["Generic_manufacturer_name"] ===
              "undefined" && (
              <>
                <Text style={styles.normal}>
                  An effective exercise of TRIPS flexibilities and successful
                  use of a Compulsory Licensing relies on two elements: on one
                  hand, a legal and regulatory framework (as above) that
                  supports government actions to unilaterally terminate the
                  rights of a patent holder, and on the other the existence
                  of/access to the technical capabilities and internationally
                  certified know-how and expertise to exploit such rights in a
                  productive and timely manner. For the latter, it is critical
                  to maintain an updated database of Good Manufacturing
                  Practices (GMP)-compliant generic and biosimilar
                  pharmaceutical companies in your country.
                </Text>
                <Text style={styles.normal}>
                  We don't have information about a specific generic
                  manufacturer in {props.countryName}. However, you can learn
                  more about general generic manufacturers in the EU{" "}
                  <a
                    href="https://www.medicinesforeurope.com/biosimilar-medicines/who-we-are/#section-4"
                    target="_blank"
                  >
                    here.
                  </a>
                </Text>
              </>
            )}
            <Text style={styles.subtitle}>Call to Action</Text>
            <Text style={styles.regular}>Take Action now!</Text>
            <Text style={styles.normal}>
              Compulsory licensing and other health- oriented IP-related tools
              offer governments the opportunity to protect public health where
              patents are an obstacle to doing so in an efficient and effective
              way. They can also be used to guarantee fair competition and
              counter market abuses. They are{" "}
              <strong>legal, legitimate and widely used</strong> have been
              effectively used around the world in the past. The information on
              this website will provide a basis for policymakers and lawmakers
              as they endeavour to make best use of these tools in national
              settings where access to new and patented medicines is putting
              strains on health systems.{" "}
            </Text>
            <Text style={styles.normal}>
              Find other resources to support the effective use of TRIPS
              Flexibilities here:
              <a href="Find other resources to support the effective use of TRIPS Flexibilities here: https://haiweb.org/what-we-do/free-trade/using-flexibilities-to-advance-access-to-medicines/ ">
                https://haiweb.org/what-we-do/free-trade/using-flexibilities-to-advance-access-to-medicines/
              </a>
            </Text>
            <Text style={styles.normal}>
              IMPORTANT TO NOTE: Data exclusivity (de facto extension of patent
              term protection) legislation associated with rare diseases, orphan
              medicines as well as paediatric formulations pose a{" "}
              <a href="https://pubmed.ncbi.nlm.nih.gov/28670455/">
                substantive obstacle
              </a>{" "}
              to the use/exercise of TRIPS flexibilities, notably compulsory
              licenses. Data exclusivity prevents generic manufacturers
              accessing innovator company information which they need to both
              develop and obtain marketing approval of generic copies. Data
              exclusivity also blocks the so-called ‘Bolar clause’ whereby
              pharmaceutical companies must make clinical trial and test data
              available to generic manufacturers twelve months before the
              expiration of a patent. The Bolar clause is intended to accelerate
              generic manufacturing, because it means a generic manufacturers
              can start work while a patent is still in force, and the generic
              can be ready for the market as soon as the patent expires. As part
              of the{" "}
              <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:52020DC0761&rid=3">
                discussion of the European Pharmaceutical Strategy
              </a>{" "}
              data exclusivity and others provisions are currently under review.
            </Text>
            <Text style={styles.footer} fixed>
              Contact: info@haiweb.org
            </Text>
            <Text style={styles.footerBottom} fixed>
              <a href="https://haiweb.org/">https://haiweb.org/</a>
            </Text>
          </Page>
          <Page size="A4">
            <Text style={styles.title}>GLOSSARY</Text>
            <Text style={styles.normal}>
              1. Bolar Exemption: A legal exception that permits the use of a
              patented invention before the patent expires for the purposes of
              obtaining marketing approval of a generic product for a
              commercialisation once the patent expires.
              <a href="https://www.google.com/url?q=https://haiweb.org/what-we-do/free-trade/making-sense-terminology/%23_ftnref1&sa=D&source=editors&ust=1628543262994990&usg=AOvVaw0A3v1nKgXHuRxVRiYKCSyv">
                [1]
              </a>
            </Text>
            <Text style={styles.normal}>
              2. Compulsory License/Government Use: A compulsory licence is an
              authorisation by a competent government authority to use a
              patented invention by a third party without the consent of the
              patent holder, against a payment of “adequate remuneration”. A
              “government use” is a particular form of compulsory licence issued
              by the government for its own use or for the use of a third party.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref2">
                [2]
              </a>
            </Text>
            <Text style={styles.normal}>
              Data Exclusivity: Prohibition of use of pharmaceutical test data
              submitted to a regulatory agency by an originator company for the
              purpose of registering a generic drug. Generic companies rely on
              this test data to demonstrate the safety and efficacy of their
              bioequivalent drug.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref3">
                [3]
              </a>
            </Text>
            <Text style={styles.normal}>
              4. Evergreening: The practice of seeking secondary patents with
              the aim to extend market exclusivity beyond the patent term of the
              basic patent.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref4">
                [4]
              </a>
            </Text>
            <Text style={styles.normal}>
              5. Most Favoured Nation (MFN) treatment: One of the founding
              principles of the World Trade Organization. MFN Treatment says
              that all trading partners must be given equal advantages as the
              “most favoured” trading partner. For example, a country may not
              grant tariff exemptions to only one trading partner unless it
              extends the same to all trading partners. [5]
            </Text>
            <Text style={styles.normal}>
              6. Parallel Importation: Parallel importation refers to the import
              and resale in a country, without the consent of the patent holder,
              of a patented product that has been legitimately put on the market
              of the exporting country. Parallel imports take place when there
              are significant price differences for the same good in different
              markets.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref6">
                [6]
              </a>
            </Text>
            <Text style={styles.normal}>
              7. Supplementary Protection Certificate: Supplementary Protection
              Certificates (SPCs) constitute an example of additional monopoly
              rights, intended to expand monopoly protection for medicines
              beyond the twenty-year patent term.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref7">
                [7]
              </a>
            </Text>
            <Text style={styles.normal}>
              8. TRIPS Flexibilities: A term used broadly to describe a set of
              norms, rules and standards that allow variations in the
              implementation of the TRIPS Agreement obligations, including
              limits on the excessive use of Intellectual Property Rights.
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref8">
                [8]
              </a>
            </Text>
            <Text style={styles.normal}>
              9. TRIPS+: These are measures that require more stringent
              intellectual property standards than those contained in TRIPS, or
              that limit flexibilities inherent in TRIPS. They are often
              contained in bilateral or regional trade agreements.{" "}
            </Text>
            <Text style={styles.normal}>
              10. Voluntary Licence: A license granted by a patent holder to a
              third party to produce and/or market and distribute a patented
              product, usually in exchange for a royalty on net sales and
              certain other conditions (for example geographical restrictions on
              where the product can be sold){" "}
              <a href="https://haiweb.org/what-we-do/free-trade/making-sense-terminology/#_ftnref9">
                [10]
              </a>
            </Text>
            <Text style={styles.footer} fixed>
              Contact: info@haiweb.org
            </Text>
            <Text style={styles.footerBottom} fixed>
              <a href="https://haiweb.org/">https://haiweb.org/</a>
            </Text>
          </Page>
          <Page size="A4">
            <Text style={styles.title}>Resources</Text>
            <Text style={styles.normal}>
              <a href="https://haiweb.org/what-we-do/free-trade/heatmap-of-trips-flexibilities-in-the-eu/">
                - HAI Heatmap
              </a>
            </Text>
            <Text style={styles.normal}>
              <a href="https://haiweb.org/wp-content/uploads/2019/06/HAI-TRIPS-Brochure-1.pdf">
                - TRIPS Brochure
              </a>
            </Text>
            <Text style={styles.normal}>
              <a href="https://e-courses.epo.org/course/view.php?id=160 ">
                - EPO Compulsory License Resource
              </a>
            </Text>
            <Text style={styles.normal}>
              <a href="https://www.medicinesforeurope.com/biosimilar-medicines/who-we-are/#section-4">
                - Medicines for Europe
              </a>
            </Text>
            <Text style={styles.footer} fixed>
              Contact: info@haiweb.org
            </Text>
            <Text style={styles.footerBottom} fixed>
              <a href="https://haiweb.org/">https://haiweb.org/</a>
            </Text>
          </Page>
        </Document>
      );
    }
  }

  function renderPDF() {
    ReactPDF.render(<MyDocument />);
  }
  const doc = renderPDF;

  return (
    <div id="action-plan">
      <div className="top">
        <div className="h3-top-holder">
          <h1>
            Action Plan for {props.profileName}s in {props.countryName}
          </h1>
        </div>

        {/* <VscGithubAction className="header-icon" /> */}
      </div>
      <div className="bottom">
        <h1>Action Plan</h1>

        <div className="Example__container__document">
          <PDFViewer style={{}} width={800} height={1000}>
            <MyDocument
              profileName={props.profileName}
              countryName={props.countryName}
              selectedRecord={props.selectedRecord}
            />
          </PDFViewer>
        </div>

        <div>
          <PDFDownloadLink
            document={<MyDocument />}
            fileName="TRIPS_Action_Plan.pdf"
          >
            {({ loading }) =>
              loading ? "Loading document..." : "Download Action Plan!"
            }
          </PDFDownloadLink>
        </div>
        <Link
          to="profile-select"
          className=""
          id="to-restart"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {
            setTimeout(function () {
              // var elem = document.querySelector("#profile-result");
              // elem.classList.add("hide");
              var elem = document.getElementById("profile-result");
              elem.remove();
              var div1 = document.getElementById("profile-select");
              var newDiv = document.createElement("div");
              newDiv.id = "profile-result";
              div1.appendChild(newDiv);
            }, 1000);
          }}
        >
          <div className="circle">
            <VscDebugRestart style={{ fontSize: 45 }} />
          </div>
          <p>Start Over</p>
        </Link>
      </div>
    </div>
  );
}

export default ActionPlan;
