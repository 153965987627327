export function getFromAirtable(airtableBase, airtableView, obj) {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");

  return new Promise((resolve, reject) => {
    base(airtableBase)
      .select({
        view: airtableView,
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            Object.values(obj).map((val) => {
              if (val.comparisonField) {
                if (record.fields[val.field] === val.comparisonField) {
                  val.data.push(record.fields);
                }
              } else if (val.field === "All") {
                val.data.push(record.fields);
              } else {
                val.data.push(record.fields[val.field]);
              }
            });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return reject({});
          }
          let result = obj;
          resolve(result);
        }
      );
  });
}
