import React, { useState } from "react";
import "./thank-you.css";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-scroll";

import ProfileSelect from "../profile-select/profile-select";

function ThankYou(props) {
  const [nextClicked, setNextClicked] = useState(false);
  return (
    <div className="thank-you-page">
      <div className="top">
        <div className="h3-top-holder">
          <h1>Thank You!</h1>
        </div>
      </div>
      <div className="bottom">
        <h3>Thank you for filling out the form!</h3>
        <h3>
          Feel free to continue to explore the navigator's other pathways or
          check out our resources!
        </h3>

        <Link
          className=""
          id="gnmf"
          activeClass="active"
          to="thank-you-result"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {
            setNextClicked(true);
          }}
        >
          <div className="circle">
            <FaArrowRight style={{ width: 55, fontSize: 45 }} />
          </div>
          <p>Explore the navigator from a different perspective </p>
        </Link>
      </div>
      <div id="thank-you-result">{nextClicked && <ProfileSelect />}</div>
    </div>
  );
}

export default ThankYou;
