/**
 * Read Text content from airtable
 */
export function getTextData(base) {
  return new Promise((resolve, reject) => {
    let data = [];
    base("Discussion Page Text")
      .select({
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            data.push([record.fields["Section"], record.fields["Text"]]);
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return reject({});
          }
          let result = {
            data,
          };
          resolve(result);
        }
      );
  });
}

/**
 * Read posts from airtable
 */
export async function getPostsData(base) {
  const commentsData = await getCommentsData(base);

  return new Promise((resolve, reject) => {
    let data = [];
    base("Discussion Posts Data")
      .select({
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            // use the comment lookup to add the comment
            const comments = record.fields.Comments
              ? record.fields.Comments.map((id) => commentsData[id])
              : null;
            const rec = {
              ...record.fields,
              Comments: comments,
              PostId: record.id,
            };
            data.push(rec);
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return reject({});
          }
          let result = {
            data,
          };
          resolve(result);
        }
      );
  });
}

/**
 * Read comments from airtable
 */
export function getCommentsData(base) {
  return new Promise((resolve, reject) => {
    let data = [];
    base("Discussion Comments Data")
      .select({
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            data.push([record.id, record.fields]);
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return reject({});
          }
          let result = Object.fromEntries(data);
          resolve(result);
        }
      );
  });
}

/**
 * Write Posts to at
 */
export function writePostToAirtable(base, inputData) {
  base("Discussion Posts Data").create(
    [
      {
        fields: {
          Title: inputData.Title || "",
          Message: inputData.Message || "",
          Author: inputData.Author || "",
          Date: new Date().toISOString().split("T")[0],
          Tags: inputData.Tags || [],
          // Comments: [], // this should be empty to start
        },
      },
    ],
    function (err) {
      if (err) {
        console.error(err);
        return;
      }
      console.log("Success! Added Post to Airtable");
    }
  );
}

/**
 * Write comments to airtable
 */
export function writeCommentsToAirtable(base, inputData) {
  // create a comment record,
  // then need to go and update the comment for the post we're looking at
  base("Discussion Comments Data").create(
    [
      {
        fields: {
          Message: inputData.Message,
          Author: inputData.Author,
          Date: new Date().toISOString().split("T")[0],
          "Discussion Posts Data": [inputData.postId],
        },
      },
    ],
    function (err) {
      if (err) {
        console.error(err);
        return;
      }
      console.log("Success! Added Comment to Airtable");
    }
  );
}
