import React, { useState, useEffect } from "react";
import "./join-community.css";
import AirtableForm from "../../../../components/form/form";
import { Button } from "react-bootstrap";

function JoinCommunity(props) {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  function addEmailToAirtable(inputEmail) {
    base("Email Collection").create(
      [
        {
          fields: {
            Email: inputEmail,
            Profile: props.profileName,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          console.log(record.fields);
        });
      }
    );
  }
  const contactForm = [{ label: "Email", placeholder: "name@example.com" }];

  const [text, setText] = useState({});
  function getData() {
    return new Promise((resolve, reject) => {
      let all = {};
      base("Join Community Page Text")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              all[record.fields["Section"]] = record.fields["Text"];
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return reject({});
            }
            let result = {
              data: all,
            };
            resolve(result);
          }
        );
    });
  }
  const fetchAllData = async () => {
    const result = await getData();
    setText(result.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <div id="email-collection">
      <div className="top">
        <div className="h3-top-holder">
          <h1>{text.title}</h1>
        </div>
      </div>
      <div className="bottom">
        <h3>{text.subtitle}</h3>
        <div className="airtable-email-form" id="email-form">
          <AirtableForm formFields={contactForm} />
          <Button
            onClick={() => {
              const email = document.getElementById("Email").value;
              addEmailToAirtable(email);
              // hide the form
              document.getElementById("email-form").style.display = "none";
              // show the thank you screen
              document.getElementById("form-submitted").style.display = "block";
              // handleClose();
            }}
          >
            Submit
          </Button>
        </div>
        <div id="form-submitted">
          <p>{text.formSubmitted1}</p>
          <p>{text.formSubmitted2}</p>
          <Button
            onClick={() => {
              // hide the thank you
              document.getElementById("form-submitted").style.display = "none";
              // show the form
              document.getElementById("email-form").style.display = "block";
            }}
          >
            Redo
          </Button>
        </div>
        <br></br>
      </div>
    </div>
  );
}

export default JoinCommunity;
