import * as React from "react";

import {
  Box,
  MobileStepper,
  Paper,
  Typography,
  Button,
  useTheme,
} from "@mui/material";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

export default function TextMobileStepper(props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, margin: "auto" }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          // height: 50,
          pl: 2,
          bgcolor: "lightgray",
          border: "2px solid darkgray",
        }}
      >
        <Typography variant="h5">{props.steps[activeStep].label}</Typography>
      </Paper>
      <Box
        sx={{
          // height: 255,
          maxWidth: 400,
          width: "100%",
          p: 2,
          border: 1,
          boxShadow: 1,
        }}
      >
        {props.steps[activeStep].description.map((item) => (
          <h6>
            <strong>{item.title.replace(/_|-/g, " ")}: </strong> {item.content}
          </h6>
        ))}
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? <FaArrowRight /> : <FaArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <FaArrowRight /> : <FaArrowLeft />}
            Back
          </Button>
        }
      />
    </Box>
  );
}
