import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function SearchSelect(props) {
  return (
    <Autocomplete
      disablePortal
      sx={{ width: 300, ...props.sx }}
      value={props.value || ""}
      onChange={props.onChange}
      id="combo-box-demo"
      options={props.options}
      renderInput={(params) => (
        <TextField {...params} label={props.searchLabel} />
      )}
    />
  );
}

export default SearchSelect;
