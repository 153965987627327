import React from "react";
import "./contact.css";
import { Button } from "react-bootstrap";
import AirtableForm from "../../../../components/form/form";

function Contact(props) {
  const contactForm = [
    { label: "Email", placeholder: "name@example.com" },
    { label: "Message", placeholder: "Your thoughts...", rows: 3 },
  ];
  return (
    <div className="contact-page">
      <div className="top">
        <div className="h3-top-holder">
          <h1>Contact Us</h1>
        </div>
      </div>
      <div className="bottom">
        <AirtableForm formFields={contactForm} />
        <Button>Submit</Button>
        <h3>Where to find us</h3>
        <p>
          <strong style={{ fontWeight: "bold" }}>Address: </strong>Health Action
          International Overtoom 60 (2) 1054 HK Amsterdam The Netherlands
        </p>
        <p>
          <strong style={{ fontWeight: "bold" }}>Phone: </strong>+31 (0) 20 412
          4523
        </p>
        <p>
          <strong style={{ fontWeight: "bold" }}>Email: </strong>info@haiweb.org
        </p>
      </div>
    </div>
  );
}

export default Contact;
