import React, { useState, useEffect } from "react";
import ActionPlan from "../action-plan/action-plan";
import { Link } from "react-scroll";
import { VscGithubAction } from "react-icons/vsc";
import "./email-collect.css";
import AirtableForm from "../../../../components/form/form";
import { getFromAirtable } from "../../../../components/utils";

function EmailCollection(props) {
  const [nextClicked, setNextClicked] = useState(false);

  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  function addEmailToAirtable(inputEmail, infoChecked, commChecked) {
    base("Email Collection").create(
      [
        {
          fields: {
            Email: inputEmail,
            Profile: props.profileName,
            More_Info: infoChecked,
            Community: commChecked,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          console.log(record.fields);
        });
      }
    );
  }
  const contactForm = [{ label: "Email", placeholder: "name@example.com" }];

  const [text, setText] = useState({});
  const fetchAllData = async () => {
    const res = await getFromAirtable("Email Page Text", "Grid view", {
      data: { data: [], field: "All" },
    });
    // convert [{section, text}] --> section: text
    const t = res.data.data.reduce(
      (obj, item) => ({ ...obj, [item.Section]: item.Text }),
      {}
    );
    setText(t);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <div id="email-collection">
      <div className="top">
        <div className="h3-top-holder">
          <h1>{text.title}</h1>
        </div>
      </div>
      <div className="bottom">
        <div className="airtable-email-form">
          <AirtableForm formFields={contactForm} />

          <label for="more-info-check">
            <input
              type="checkbox"
              id="more-info-check"
              name="more-info-check"
              value="More_Info"
            />
            {text["checkbox 1"]}
          </label>
          <br></br>
          <label for="community-check">
            <input
              type="checkbox"
              id="community-check"
              name="community-check"
              value="Community"
            />
            {text["checkbox 2"]}
          </label>
          <br></br>
        </div>
        <Link
          className=""
          id="to-action-plan"
          activeClass="active"
          to="email-collect-result"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {
            // create new record in email airtable field with this email
            // then set next clicked
            const email = document.getElementById("Email").value;
            const info = document.getElementById("more-info-check").checked;
            const comm = document.getElementById("community-check").checked;
            console.log("email: ", email, info, comm);
            addEmailToAirtable(email, info, comm);
            setNextClicked(true);
          }}
        >
          <div className="circle">
            <VscGithubAction style={{ width: 55, fontSize: 45 }} />
          </div>
          <p>Continue to your personalized Action Plan!</p>
        </Link>
      </div>

      <div id="email-collect-result">
        {nextClicked && (
          <ActionPlan
            profileName={props.profileName}
            countryName={props.countryName}
            selectedRecord={props.selectedRecord}
            tripsCountries={props.tripsCountries}
            tripsOriginators={props.tripsOriginators}
            tripsConditions={props.tripsConditions}
          />
        )}
      </div>
    </div>
  );
}

export default EmailCollection;
