import React from "react";

import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { Nav } from "react-bootstrap";
import "./index.css";
import ProfileSelect from "./components/profile-select/profile-select";
import FAQS from "./components/FAQS/FAQS";
import Contact from "./components/contact/contact";
import Welcome from "./components/welcome/welcome";
import About from "./components/about/about";
import Glossary from "./components/glossary/glossary";
import Resources from "./components/resources/resources";
import Referrals from "./components/referrals/referrals";
import Calendar from "./components/calendar/calendar";
import { Link } from "react-scroll";
import JoinCommunity from "./components/join-community/join-community";
import Discussion from "./components/discussion/discussion";

function PageRoute(props) {
  // this is how we'll change the path that a user is taken down

  function showSectionOnNavClicked(sectionName, setActive) {
    let showSection = document.getElementById(sectionName);
    let currentShow = document.getElementsByClassName("show");
    let active = document.getElementsByClassName("active");

    for (const element of currentShow) {
      element.classList.remove("show");
      element.classList.add("hide");
    }
    for (const item of active) {
      item.classList.remove("active");
    }
    showSection.classList.remove("hide");
    showSection.classList.add("show");
    setActive.add("active");
  }

  function openNav() {
    document.getElementById("main-sidebar").style.width = "250px";
    // document.getElementById("explore-nav-push").style.marginLeft = "250px";
    document.getElementById("all-content").style.marginLeft = "250px";
    document.getElementById("all-content").style.backgroundColor = "red";
    document.getElementById("icon-holder").style.transitionDelay = "0.3s";
    document.getElementById("icon-holder").style.transitionProperty =
      "visibility";
    document.getElementById("icon-holder").style.visibility = "visible";

    document.getElementById("overlay").style.visibility = "visible";
    document.getElementById("overlay").style.left = "250px";
  }

  function closeNav() {
    document.getElementById("main-sidebar").style.width = "0";
    // document.getElementById("explore-nav-push").style.marginLeft = "0px";
    document.getElementById("all-content").style.marginLeft = "0";
    document.getElementById("icon-holder").style.transitionDelay = "0s";
    document.getElementById("icon-holder").style.transitionProperty =
      "visibility";
    document.getElementById("icon-holder").style.visibility = "hidden";

    document.getElementById("overlay").style.left = "0px";
    document.getElementById("overlay").style.visibility = "hidden";
    // document.getElementById("faqs").style.paddingTop = "50px";
  }

  return (
    <div className="all-content" id="all-content">
      <Nav id="main-sidebar" class="sidebar">
        <Link className="level1-link closebtn" onClick={() => closeNav()} to="">
          <CgClose />
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="explore"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("welcome", e.target.classList);
          }}
        >
          Explore
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="faqs"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("faqs", e.target.classList);
            document.getElementById("back-button").style.display = "none";
          }}
        >
          FAQ
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="glossary"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("glossary", e.target.classList);
          }}
        >
          Glossary
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("about", e.target.classList);
          }}
        >
          About
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="join-community"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("join-community", e.target.classList);
          }}
        >
          Join the Community
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("contact", e.target.classList);
          }}
        >
          Contact
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="calendar"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("calendar", e.target.classList);
          }}
        >
          Calendar
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="resources"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("resources", e.target.classList);
          }}
        >
          Resources
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="referrals"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("referrals", e.target.classList);
          }}
        >
          Refer a Friend
        </Link>
        <Link
          className="level1-link"
          activeClass="active"
          to="discussion"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={(e) => {
            showSectionOnNavClicked("discussion", e.target.classList);
          }}
        >
          Community Discussion
        </Link>

        <div className="icon-holder" id="icon-holder">
          <hr style={{ zIndex: 10, color: "#f2f2f2" }} />
          <a
            href="https://www.instagram.com/healthactioninternational/"
            target="_blank"
          >
            <FaInstagram />
          </a>
          <a href="https://www.facebook.com/HAImedicines" target="_blank">
            <FaFacebook />
          </a>
          <a href="https://twitter.com/HAImedicines" target="_blank">
            <FaTwitter />
          </a>
          <a
            href="https://www.linkedin.com/company/health-action-international-hai-europe/"
            target="_blank"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.youtube.com/user/StichtingHAIEurope"
            target="_blank"
          >
            <FaYoutube />
          </a>
        </div>
      </Nav>

      <Nav id="navbar">
        <button className="openbtn" onClick={() => openNav()}>
          ☰ Menu
        </button>

        <div id="extra-navbar">
          <Link
            to="/home"
            className="level1-link"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
          >
            <a href="https://haiweb.org/what-we-do/free-trade/heatmap-of-trips-flexibilities-in-the-eu/">
              <img
                src={require("../../assets/HAI-logo.jpg")}
                className="sidebar-logo"
              />
            </a>
          </Link>
        </div>
      </Nav>

      <div
        className="dark-overlay"
        id="overlay"
        onClick={() => closeNav()}
      ></div>
      <div className="main show" id="welcome">
        <Welcome />
      </div>
      <div className="main hide" id="section-1">
        <ProfileSelect />
      </div>

      <div className="main hide" id="faqs">
        <FAQS />
      </div>
      <div className="main hide" id="about">
        <About />
      </div>
      <div className="main hide" id="join-community">
        <JoinCommunity />
      </div>
      <div className="main hide" id="contact">
        <Contact />
      </div>
      <div className="main hide" id="glossary">
        <Glossary />
      </div>
      <div className="main hide" id="calendar">
        <Calendar />
      </div>
      <div className="main hide" id="resources">
        <Resources />
      </div>
      <div className="main hide" id="referrals">
        <Referrals />
      </div>
      <div className="main hide" id="discussion">
        <Discussion />
      </div>
    </div>
  );
}

export default PageRoute;
