import React, { useState, useEffect } from "react";
import "./resources.css";

function Resources(props) {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");

  const [resources, setResources] = useState([]);
  function getData() {
    return new Promise((resolve, reject) => {
      let abtxt = [];
      base("Resources Page Text")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              let obj = {
                title: record.fields["Text"],
                description: record.fields["Description"],
                link: record.fields["URL"],
              };
              abtxt.push(obj);
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return reject({});
            }
            let result = {
              data: abtxt,
            };
            resolve(result);
          }
        );
    });
  }
  const fetchAllData = async () => {
    const res = await getData();
    setResources(res.data);
    console.log("res.data resources: ", res.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <>
      <div>
        <div className="top">
          <div className="h3-top-holder">
            <h1>Resources </h1>
          </div>
        </div>
        <div className="bottom">
          <div className="faq-section">
            <h3>Find out more about TRIPS Flexibilities: </h3>
            <ul>
              {resources.map((el, index) => (
                <li key={index}>
                  <a href={el.link}>{el.title}</a>
                  <p>{el.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Resources;
