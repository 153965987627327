import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Button, Paper, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function Cookies(initialOpen) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [initOpen, setInitOpen] = useState(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setInitOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  const [cookiesText, setCookiesText] = useState("");
  function getData() {
    return new Promise((resolve, reject) => {
      let cookiesData = {};
      base("Cookies Text")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              if (record.fields["Text_Block_ID"] === "Cookies Title") {
                cookiesData.title = record.fields["Text"];
              }
              if (record.fields["Text_Block_ID"] === "Cookies Body") {
                cookiesData.body = record.fields["Text"];
              }
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return reject({});
            }
            let result = cookiesData;
            resolve(result);
          }
        );
    });
  }
  const fetchAllData = async () => {
    const res = await getData();
    setCookiesText(res);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <div>
      <Popover
        id={id}
        open={initOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Paper
          sx={{
            color: "#rgb(41, 52, 89)",
            backgroundColor: "rgba(67, 99, 216, 0.25)",
            minWidth: 250,
            minHeight: 120,
            float: "right",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                pt: 1,
                ml: 2,
                mr: 2,
                fontWeight: 600,
                float: "left",
              }}
              variant="h7"
            >
              {cookiesText.title}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", top: 0, right: 0 }}
              size="small"
            >
              <CloseIcon size="small" />
            </IconButton>
          </Box>
          <Typography sx={{ ml: 2, mr: 2, fontSize: 12 }}>
            {cookiesText.body}
          </Typography>
        </Paper>
      </Popover>
    </div>
  );
}

export default Cookies;
