import React from "react";

import Amplify from "aws-amplify";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { hotjar } from "react-hotjar";

import PageRoute from "./routes/improve-access";

import config from "./config/aws-config";
import "./App.css";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

function App() {
  hotjar.initialize(2611406, 6);
  hotjar.identify("USER_ID", { userProperty: "value" });
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <PageRoute />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
