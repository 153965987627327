import React, { useState } from "react";

import {
  Box,
  Grid,
  Typography,
  Chip,
  Card,
  Tooltip,
  IconButton,
  Dialog,
  Button,
  Divider,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { Comments, CreateNewComment } from "./comment";
import { PostForm } from "./forms";

const PostContent = (props) => (
  <Box
    sx={{
      backgroundColor: "#F1F1F1",
      borderRadius: 1,
      minHeight: 100,
      m: 2,
    }}
  >
    {props.message && (
      <Typography variant="body1" sx={{ m: 2, pt: 1, pb: 1 }}>
        {props.message}
      </Typography>
    )}
  </Box>
);

const Post = (props) => {
  const [isNewCommentOpen, setIsNewCommentOpen] = useState(false);
  const [isViewCommentsOpen, setIsViewCommentsOpen] = useState(false);
  return (
    // create a styled card for this
    <Card sx={{ mt: 2 }}>
      <Box sx={{ m: 2 }}>
        <Grid container spacing={3}>
          <Grid item sm={10} xs={12} md={10}>
            {props.title && <Typography variant="h4">{props.title}</Typography>}
            <Grid item>
              {props.date && (
                <Typography variant="caption">
                  <strong>Posted:</strong> {props.date}
                </Typography>
              )}
            </Grid>
            <Grid item>
              {props.author && (
                <Typography variant="caption">
                  <strong>By:</strong> {props.author}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item sm={2} xs={12} md={2}>
            {props.tags &&
              props.tags.map((tag) => (
                <Chip
                  label={tag}
                  variant="outlined"
                  sx={{ m: 0.5 }}
                  size="small"
                />
              ))}
          </Grid>
        </Grid>
        <PostContent message={props.message} />

        <Grid container sx={{ position: "relative", mb: 4 }}>
          <Grid item sm={10} xs={10} md={10} sx={{ mt: 2 }}>
            {props.comments && (
              <Typography variant="caption">
                <Button
                  onClick={() => setIsViewCommentsOpen(!isViewCommentsOpen)}
                >
                  View {props.comments.length} Comments
                </Button>
              </Typography>
            )}
          </Grid>
          <Grid
            item
            sm={2}
            xs={2}
            md={2}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              mt: props.comments ? 2 : 0,
            }}
          >
            <Tooltip title="Add Comment">
              <IconButton onClick={() => setIsNewCommentOpen(true)}>
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={isNewCommentOpen}
        onClose={() => setIsNewCommentOpen(false)}
        sx={{ maxWidth: "none", maxHeight: "none" }}
      >
        <CreateNewComment
          handleClose={() => setIsNewCommentOpen(false)}
          description={props.description}
          postId={props.postId}
        />
      </Dialog>

      {/** VIEW COMMENTS */}
      {props.comments && (
        <Dialog
          open={isViewCommentsOpen}
          onClose={() => setIsViewCommentsOpen(false)}
          sx={{ maxWidth: "none", maxHeight: "none" }}
        >
          <Box sx={{ minWidth: 600, maxWidth: "none" }}>
            {/** Put the main part of the  post in here too*/}
            <Box sx={{ m: 2 }}>
              {props.title && (
                <Typography variant="h4">{props.title}</Typography>
              )}
              <PostContent message={props.message} />
              <Divider light sx={{ mb: 1, mt: 2, color: "lightgray" }} />
            </Box>
            <Box
              sx={{
                m: 4,
              }}
            >
              <Comments comments={props.comments} />
            </Box>
          </Box>
        </Dialog>
      )}
    </Card>
  );
};

export const Posts = (props) => {
  return props.posts.map((post) => (
    <Post
      message={post.Message}
      title={post.Title}
      tags={post.Tags}
      author={post.Author}
      date={post.Date}
      comments={post.Comments}
      description={props.Description}
      postId={post.PostId}
    />
  ));
};

// create new post item
export const CreateNewPost = (props) => {
  return (
    <Box sx={{ m: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {props.description}
      </Typography>
      <PostForm handleClose={props.handleClose} />
    </Box>
  );
};
