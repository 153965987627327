import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-scroll";
import { FaArrowRight } from "react-icons/fa";
import ProfileSelect from "../profile-select/profile-select";
import ThankYou from "../thank-you/thank-you";

function Feedback(props) {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  function addEmailToAirtable(email, feedback) {
    base("Feedback").create(
      [
        {
          fields: {
            Feedback: feedback,
            Email: email,
            Reviewed: false,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          console.log(record.fields);
        });
      }
    );
  }
  const [nextClicked, setNextClicked] = useState(false);
  const [profileClicked, setProfileClicked] = useState(false);

  return (
    <div id="feedback">
      <div className="top">
        <div className="h3-top-holder">
          <h1>Join the Community & Tell Us What You Think!</h1>
        </div>
      </div>
      <div className="bottom">
        <h4>
          Want to join our growing community of researchers? Enter your email
          below!
        </h4>
        <div className="airtable-email-form">
          <form
            action="mailto:someone@example.com"
            method="post"
            enctype="text/plain"
          >
            Email:
            <br />
            <input type="text" name="mail" id="email-textbox"></input>
            <br />
          </form>
        </div>
        <h4>
          Have suggestions, comments, or questions? Fill out our feedback form
          below!
        </h4>
        <div className="airtable-email-form">
          <form
            action="mailto:someone@example.com"
            method="post"
            enctype="text/plain"
          >
            Feedback:
            <br />
            <textarea rows="5" cols="50" id="feedback-textbox"></textarea>
            <br />
          </form>
        </div>
        <Link
          className=""
          id=""
          activeClass="active"
          to="feedback-result"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {
            // create new record in email airtable field with this email
            // then set next clicked

            const email = document.getElementById("email-textbox").value;
            const feedback = document.getElementById("email-textbox").value;

            addEmailToAirtable(email, feedback);

            setNextClicked(true);
          }}
        >
          <Button>Submit</Button>
        </Link>
        <div>
          <Link
            className=""
            id="gnmf"
            activeClass="active"
            to="feedback-result"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            onClick={() => {
              setProfileClicked(true);
            }}
          >
            <div className="circle">
              <FaArrowRight style={{ width: 55, fontSize: 45 }} />
            </div>
            <p>Or explore the navigator from a different perspective </p>
          </Link>
        </div>
      </div>

      <div id="feedback-result">
        {nextClicked && <ThankYou />}
        {profileClicked && <ProfileSelect />}
      </div>
    </div>
  );
}

export default Feedback;
