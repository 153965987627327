import React, { useState, useEffect } from "react";

import { Button, Col, Row } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EmailCollection from "../email-collect/email-collect";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-scroll";
import "./generic-manufacturers.css";
import AirtableForm from "../../../../components/form/form";
import { Dialog, Typography, Button as MuiButton } from "@mui/material";
import { getFromAirtable } from "../../../../components/utils";

function GenericManufacturers(props) {
  function addToAirtable(inputName, inputLink, inputCountry) {
    base("Generic Manufacturers").create(
      [{ fields: { Name: inputName, URL: inputLink, Country: inputCountry } }],
      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          console.log(record.getId());
        });
      }
    );
  }
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  const [nextClicked, setNextClicked] = useState(false);

  const gmForm = [
    { label: "Manufacturer Name", placeholder: "Manufacturer" },
    { label: "URL", placeholder: "Link to website" },
    { label: "Country", placeholder: "Country" },
  ];

  const [gms, setGms] = useState([]);
  function getGMData() {
    return new Promise((resolve, reject) => {
      let gms = [];
      base("Additional Country Data")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              if (record.fields["Generic_manufacturer_name"]) {
                gms.push({
                  name: record.fields["Generic_manufacturer_name"],
                  country: record.fields["Country name"],
                  link: record.fields["Generic_manufacturer_link"],
                });
              }
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return reject({});
            }
            let result = {
              data: gms,
            };
            resolve(result);
          }
        );
    });
  }

  const [text, setText] = useState({});

  const fetchAllData = async () => {
    const res = await await getFromAirtable(
      "Generic Manufacturers Page Text",
      "Grid view",
      {
        data: { data: [], field: "All" },
      }
    );
    const t = res.data.data.reduce(
      (obj, item) => ({ ...obj, [item.Section]: item.Text }),
      {}
    );
    setText(t);
    const gm = await getGMData();
    setGms(gm.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#073763",
      color: theme.palette.common.white,
      zIndex: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
    },
  }));

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div id="generic-manufacturers">
      <div className="top">
        <div className="h3-top-holder">
          <h1>{text.title}</h1>
        </div>
      </div>
      <div className="gm bottom">
        <Row style={{ marginLeft: 60, marginRight: 60 }}>
          <Col className="legislation-info2">
            <p style={{ textAlign: "left" }}>
              {text["block 1"]}{" "}
              <a href="https://www.medicinesforeurope.com/biosimilar-medicines/who-we-are/#section-4">
                here.
              </a>
            </p>
            <p style={{ textAlign: "left" }}>{text["block 2"]}</p>
          </Col>
          <div className="vl"></div>
          <Col className="legislation-info2">
            <h6>{text["table caption"]}</h6>
            <TableContainer
              sx={{ maxHeight: 440, maxWidth: 500, minWidth: 100 }}
            >
              <Table
                stickyHeader
                sx={{ maxWidth: 500, maxHeight: 440, minWidth: 100 }}
                aria-label="gm-table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Manufacturer Name</StyledTableCell>
                    <StyledTableCell>Country</StyledTableCell>
                    <StyledTableCell>Link</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gms.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell>{row.country}</StyledTableCell>
                      <StyledTableCell>
                        <a href={row.link}>{row.link}</a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p>
              Know of other generic manufacturers? Click{" "}
              <MuiButton onClick={handleClickOpen} variant="text">
                here
              </MuiButton>
              <Dialog
                open={open}
                onClose={handleClose}
                sx={{ minWidth: 900, minHeight: 700 }}
              >
                <div className="gm-dialog">
                  <Typography variant="h4" sx={{ mt: 2, ml: 2 }}>
                    Add a Manufacturer
                  </Typography>
                  <div className="airtable-email-form">
                    <div id="gm-form">
                      <AirtableForm formFields={gmForm} />
                      <Button
                        onClick={() => {
                          const name =
                            document.getElementById("Manufacturer Name").value;
                          const link = document.getElementById("URL").value;
                          const country =
                            document.getElementById("Country").value;
                          addToAirtable(name, link, country);
                          // hide the form
                          document.getElementById("gm-form").style.display =
                            "none";
                          // show the thank you screen
                          document.getElementById(
                            "form-submitted"
                          ).style.display = "block";
                          // handleClose();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                    <div id="form-submitted">
                      <p>Thank you for submitting the form!</p>
                      <p>
                        Have more information? Click here to fill out the form
                        again!
                      </p>
                      <Button
                        onClick={() => {
                          // hide the thank you
                          document.getElementById(
                            "form-submitted"
                          ).style.display = "none";
                          // show the form
                          document.getElementById("gm-form").style.display =
                            "block";
                        }}
                      >
                        Redo
                      </Button>
                    </div>
                  </div>
                  <Button onClick={handleClose}>Close</Button>
                </div>
              </Dialog>{" "}
              to tell us about them.
            </p>
          </Col>
        </Row>

        <Link
          className=""
          id="to-email-collection"
          activeClass="active"
          to="generic-manufacturers-result"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {
            setNextClicked(true);
          }}
        >
          <div className="circle">
            <HiOutlineMail style={{ width: 55, fontSize: 45 }} />
          </div>
          <p>One more step before receiving your action plan!</p>
        </Link>
      </div>
      <div id="generic-manufacturers-result">
        {nextClicked && (
          <EmailCollection
            profileName={props.profileName}
            countryName={props.countryName}
            selectedRecord={props.selectedRecord}
            tripsCountries={props.tripsCountries}
            tripsOriginators={props.tripsOriginators}
            tripsConditions={props.tripsConditions}
          />
        )}
      </div>
    </div>
  );
}

export default GenericManufacturers;
