import React, { useState, useEffect } from "react";
import "./about.css";
import { getFromAirtable } from "../../../../components/utils";

function About(props) {
  const [text, setText] = useState({});
  const fetchAllData = async () => {
    const res = await getFromAirtable("About Page Text", "Grid view", {
      data: { data: [], field: "All" },
    });
    // convert [{section, text}] --> section: text
    const t = res.data.data.reduce(
      (obj, item) => ({ ...obj, [item.Section]: item.Text }),
      {}
    );
    setText(t);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <div className="about-page">
      <div className="top">
        <div className="h3-top-holder">
          <h1>{text.title}</h1>
        </div>
      </div>
      <div className="bottom">
        <h2>{text["subtitle1"]}</h2>
        <p>{text["section1"]}</p>
        <p>{text["section2"]}</p>
        <h2>{text["subtitle2"]}</h2>
        <p>{text["section3"]}</p>
        <p>{text["section4"]}</p>
      </div>
    </div>
  );
}

export default About;
