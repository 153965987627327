import React, { useState } from "react";

import {
  TextField,
  Button,
  Select,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Box,
  Alert,
} from "@mui/material";

import { writeCommentsToAirtable, writePostToAirtable } from "./data";

// add base in here

const SubmitButton = (props) => {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  // if required fields are missing,
  // show an alert
  return (
    <Button
      onClick={() => {
        const dataMap = Object.fromEntries(
          props.fields.map((field) =>
            field.includes("Message")
              ? ["Message", document.getElementById(field).value]
              : [field, document.getElementById(field).value]
          )
        );

        if (
          Object.keys(dataMap).some(
            (key) => props.requiredFields.includes(key) && dataMap[key] === ""
          )
        ) {
          // send the alert
          props.submitFailed(true);
        } else {
          // run the write method
          props.onSubmit(base, {
            ...dataMap,
            ...props.mapOverride,
            postId: props.postId,
          });

          // in both cases, we want to close the modal
          props.handleClose();
        }
      }}
    >
      Submit
    </Button>
  );
};

export const PostForm = (props) => {
  const [selectedTag, setSelectedTag] = useState("General");
  const [showAlert, setShowAlert] = useState(false);

  // display none
  return (
    <Box component="form" validate>
      {showAlert && (
        <Alert severity="error" sx={{ mb: 2, mt: -2 }}>
          Please fill out all required fields before submitting
        </Alert>
      )}

      <FormControl>
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <InputLabel id="tag-select-label">Post Type</InputLabel>
            <Select
              id="Tags"
              fullWidth
              labelId="tag-select-label"
              value={selectedTag}
              onChange={(e) => setSelectedTag(e.target.value)}
              label="Post Type"
            >
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Event">Event</MenuItem>
              <MenuItem value="Question">Question</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <TextField
              required
              fullWidth
              id="Title"
              margin="normal"
              variant="outlined"
              label="Title"
              placeholder="Your new post"
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <TextField
              required
              fullWidth
              id="Author"
              margin="normal"
              variant="outlined"
              label="Author"
              placeholder="Your name or nickname"
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <TextField
              required
              fullWidth
              multiline
              id="Post-Message"
              margin="normal"
              variant="outlined"
              rows={4}
              label="Message"
              placeholder="Your post content"
            />
          </Grid>
        </Grid>

        <SubmitButton
          fields={["Title", "Author", "Post-Message"]}
          handleClose={props.handleClose}
          onSubmit={writePostToAirtable}
          mapOverride={{ Tags: [selectedTag] }}
          requiredFields={["Title", "Author", "Message"]}
          submitFailed={(res) => setShowAlert(res)}
        />
      </FormControl>
    </Box>
  );
};

export const CommentForm = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  // show/hide form based on state of filled out
  // initially hidden, but if we hit submit, that's when we want to update it
  return (
    <FormControl>
      {showAlert && (
        <Alert severity="error" sx={{ mb: 1 }}>
          Please fill out all required fields before submitting
        </Alert>
      )}
      <Grid container>
        <Grid item xs={12} md={12} sm={12}>
          <TextField
            required
            fullWidth
            id="Author"
            margin="normal"
            variant="outlined"
            label="Author"
            placeholder="Your name or nickname"
          />
        </Grid>
        <Grid item xs={12} md={12} sm={12}>
          <TextField
            required
            multiline
            fullWidth
            margin="normal"
            variant="outlined"
            rows={4}
            id="Comment-Message"
            label="Comment"
            placeholder="Your comment"
          />
        </Grid>
      </Grid>
      <SubmitButton
        fields={["Author", "Comment-Message"]}
        handleClose={props.handleClose}
        onSubmit={writeCommentsToAirtable}
        postId={props.postId}
        requiredFields={["Author", "Message"]}
        submitFailed={(res) => setShowAlert(res)}
      />
    </FormControl>
  );
};
