import React from "react";
import "./referrals.css";
import { Button } from "react-bootstrap";
import AirtableForm from "../../../../components/form/form";

function Referrals(props) {
  const contactForm = [
    { label: "Name", placeholder: "" },
    { label: "Email", placeholder: "" },
    {
      label: "Message",
      placeholder: "Your message...",
      defaultValue: "Your message...",
      rows: 3,
    },
  ];
  return (
    <div className="referrals-page">
      <div className="top">
        <div className="h3-top-holder">
          <h1>Refer a Friend</h1>
        </div>
      </div>
      <div className="bottom">
        <h5>Know someone else who may be interested in these resources? </h5>
        <AirtableForm formFields={contactForm} />
        <Button>Submit</Button>
      </div>
    </div>
  );
}

export default Referrals;
