import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./country-select.css";
import LegalBasis from "../legal-basis/legal-basis";
import Map from "../map/map";
import { Popover2 } from "@blueprintjs/popover2";
import { getFromAirtable } from "../../../../components/utils";

function CountrySelect(props) {
  const [allData, setAllData] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  const [country, setCountry] = useState("");
  const [countrySelected, setCountrySelected] = useState(false);

  // when we do set country, we also want to set the main record so that all that data is accessible
  const [allCountries, setAllCountries] = useState([]);
  const [tripsCountries, setTripsCountries] = useState("");
  const [tripsConditions, setTripsConditions] = useState("");
  const [tripsOriginators, setTripsOriginators] = useState("");
  const [text, setText] = useState({});

  const [tripsInfo, setTripsInfo] = useState([]);
  const [mapData, setMapData] = useState([]);
  const fetchAllData = async () => {
    const res = await getFromAirtable("Additional Country Data", "Grid view", {
      data: { data: [], field: "All" },
      countries: { data: [], field: "Country name" },
      tripsUsed: {
        data: [],
        field: "Past_use",
        comparisonField: "true",
      },
    });
    setAllData(res.data.data);
    setAllCountries(res.countries.data);
    const eu = [];
    for (const country of res.countries.data) {
      eu.push({ id: country, count: 1 });
    }
    setMapData(eu);
    // let us know the data has loaded
    setTripsInfo(res.tripsUsed.data);

    const countryText = await getFromAirtable(
      "Countries Page Text",
      "Grid view",
      {
        data: { data: [], field: "All" },
      }
    );
    // convert [{section, text}] --> section: text
    const t = countryText.data.data.reduce(
      (obj, item) => ({ ...obj, [item.Section]: item.Text }),
      {}
    );
    setText(t);

    setAllDataLoaded(true);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const [countryItems, setCountryItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState({});

  function createStringFromList(list1) {
    let str = "";
    for (let i = 0; i < list1.length - 1; i++) {
      str += list1[i] + ", ";
    }
    str += "and " + list1[list1.length - 1];
    return str;
  }

  useEffect(() => {
    if (allDataLoaded) {
      let selectOptions = [];
      for (let country of allCountries) {
        selectOptions.push({ value: country, label: country });
      }
      setCountryItems(selectOptions);

      let usedConditions = new Set();
      let usedCountries = new Set();
      let usedOriginators = new Set();
      for (let rec of tripsInfo) {
        for (let r of rec["Disease"].split(",")) {
          usedConditions.add(r);
        }
        for (let r of rec["Originator"].split(",")) {
          usedOriginators.add(r);
        }
        for (let r of rec["Country name"].split(",")) {
          usedCountries.add(r);
        }
      }

      setTripsConditions(createStringFromList([...usedConditions]));
      setTripsCountries(createStringFromList([...usedCountries]));
      setTripsOriginators(createStringFromList([...usedOriginators]));

      setAllDataLoaded(false);
    }
  }, [allDataLoaded]);

  const [nextButtonClicked, setNextButtonClicked] = useState(false);

  const [multi, setMulti] = useState([]);

  const CountryResult = () => (
    <LegalBasis
      profileName={props.profileName}
      selectedRecord={selectedRecord}
      countryName={country}
      tripsCountries={tripsCountries}
      tripsConditions={tripsConditions}
      tripsOriginators={tripsOriginators}
      sectionNum={4}
      multiRecord={multi}
    />
  );

  useEffect(() => {
    if (nextButtonClicked) {
      document.getElementById("country-result").scrollIntoView({
        top: 400,
        behavior: "smooth",
      });
    }
  }, [nextButtonClicked]);

  return (
    <div id="country-select">
      <div className="top">
        <div className="h3-top-holder">
          <h1>{text.title}</h1>
        </div>
      </div>
      <div className="bottom">
        <h4>{text.select}</h4>
        <div className="country-selector">
          <Select
            defaultValue={selectedOption}
            onChange={(option) => {
              if (allCountries.indexOf(option.label) > -1) {
                setCountry(option.label);
                setCountrySelected(true);
                for (let record of allData) {
                  if (record["Country name"] === option.label) {
                    setSelectedRecord(record);
                  }
                }
                setNextButtonClicked(true);
                document.getElementById("country-result").scrollIntoView({
                  top: 400,
                  behavior: "smooth",
                });
              }
            }}
            options={countryItems}
          />
        </div>

        <div>
          <Map
            onClick={(e) => {
              if (allCountries.indexOf(e.properties.name) > -1) {
                setCountry(e.properties.name);
                setCountrySelected(true);
                for (let record of allData) {
                  if (record["Country name"] === e.properties.name) {
                    setSelectedRecord(record);
                  }
                }
                setNextButtonClicked(true);
                document.getElementById("country-result").scrollIntoView({
                  top: 400,
                  behavior: "smooth",
                });
              }
            }}
            chartTransform={[
              {
                lookup: "properties.name",
                from: {
                  data: { values: mapData },
                  key: "id",
                  fields: ["count", "id"],
                },
              },
            ]}
          />
        </div>
        <div id="more-countries">
          <Popover2
            content={
              <div className="modal-content">
                <h2 className="title"></h2>
                <div className="generic-contents">
                  Right now, you might not see your country here yet. Currently,
                  this navigator is focused only on EU member countries.
                  However, we are always looking to expand. If you have
                  information regarding TRIPS Flexibilities in your country,
                  contact us!
                </div>
              </div>
            }
          >
            <div className="about-button">
              <h5>
                Can't select your country? Click{" "}
                <span className="underline">here</span> to find out why.
              </h5>
            </div>
          </Popover2>
        </div>
      </div>
      <div id="country-result">{nextButtonClicked && <CountryResult />}</div>
    </div>
  );
}

export default CountrySelect;
