import React, { createElement, useState } from "react";
import { AiOutlineQuestion, AiOutlineLike } from "react-icons/ai";

// import link from other package
import { Link } from "react-scroll";
import Intro from "../intro/intro";
import { Popover2 } from "@blueprintjs/popover2";
import Cookies from "../cookies-alert/cookies";

import "./welcome.css";

function Welcome(props) {
  function showSectionOnNavClicked(sectionName, setActive) {
    let showSection = document.getElementById(sectionName);
    let currentShow = document.getElementsByClassName("show");
    let active = document.getElementsByClassName("active");

    for (const element of currentShow) {
      element.classList.remove("show");
      element.classList.add("hide");
    }
    for (const item of active) {
      item.classList.remove("active");
    }
    console.log("section name, showsection: ", sectionName, showSection);
    showSection.classList.remove("hide");
    showSection.classList.add("show");
    setActive.add("active");
  }

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const handleClose = () => {
    setIsOverlayOpen(false);
  };
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  return (
    <div>
      <div className="main_page" id="main-page">
        <div class="hero-image">
          <div class="hero-text">
            <header>TRIPS Flexibilities Navigator</header>
          </div>
        </div>

        <div className="welcome bottom">
          <p>
            This site is designed to be a living resource that can help to
            educate lawmakers, policymakers, researchers, and the public about
            how they can use{" "}
            <Popover2
              content={
                <div className="modal-content">
                  <h1 className="title">What are TRIPS Flexibilities?</h1>
                  <div className="generic-contents">
                    <p>
                      The term TRIPS Flexibilities is used broadly to describe a
                      set of norms, rules and standards that allow variations in
                      the implementation of the TRIPS Agreement obligations,
                      including limits on the excessive use of Intellectual
                      Property Rights.
                    </p>
                  </div>
                </div>
              }
              enforceFocus={false}
            >
              <a className="underline">TRIPS Flexibilities</a>
            </Popover2>{" "}
            to improve access to patented medicines. The information here is
            currently limited to European Union Member States, and focuses on
            the use of Compulsory Licensing, with the geographical and topic
            scope widening with the tool over time.
          </p>
          <div className="welcome-circles">
            <div className="welcome-circle-holder">
              <Link
                className=""
                activeClass=""
                to="faqs"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                onClick={(e) => {
                  showSectionOnNavClicked("faqs", e.target.classList);
                  // show back button if we navigate from explore
                  document.getElementById("back-button").style.display =
                    "block";
                }}
              >
                <div className="circle">
                  <AiOutlineQuestion size="55px" />
                </div>
                <p>I have some questions first.</p>
              </Link>
            </div>
            <div className="welcome-circle-holder">
              <Link
                className=""
                activeClass="active"
                to="intro"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                onClick={(e) => {
                  setNextButtonClicked(true);
                  document.getElementById("intro").classList.remove("hide");
                }}
              >
                <div className="circle">
                  <AiOutlineLike size="55px" />
                </div>
                <p>Sounds great! Let's get started.</p>
              </Link>
            </div>
          </div>
          <Cookies initialOpen={true} />
        </div>
      </div>
      <div id="intro">{nextButtonClicked && <Intro />}</div>
    </div>
  );
}

export default Welcome;
