import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SimpleAccordion(props) {
  return (
    <div>
      {props.accordionContent.map((el, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}a-content`}
            id={`panel${index + 1}a-header`}
          >
            <Typography>{el.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{el.description}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
