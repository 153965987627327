import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { CgProfile } from "react-icons/cg";
import { HiArchive, HiOutlineMail } from "react-icons/hi";
import { GoLaw } from "react-icons/go";
import { FaClinicMedical, FaGlobeEurope } from "react-icons/fa";
import { VscGithubAction } from "react-icons/vsc";
import { GiOpenBook } from "react-icons/gi";
import { Link } from "react-scroll";
import { AiOutlineLike } from "react-icons/ai";
import FadeIn from "react-fade-in";
import { Nav } from "react-bootstrap";
import { Popover2 } from "@blueprintjs/popover2";
import "./intro.css";
import ProfileSelect from "../profile-select/profile-select";
import { getFromAirtable } from "../../../../components/utils";

function Intro(props) {
  const profileContents =
    "At this step, you tell us a little about your background so we can create a customized action plan that's tailored to you.";
  const dataResourcesContents =
    "Once you have selected your profile and country of interest, we'll start you off on your journey to making medicines more accessible by telling you about the legal grounds and past use of TRIPS flexibilities in your country.";
  const actionContents =
    "The action plan is the final piece of this interactive journey. Based on the information you provided us, we create a custom action plan that is relevant to you. This plan can be exported easily as a pdf for simple viewing and sharing.";

  const [nextButtonClicked, setNextButtonClicked] = useState(false);

  const [text, setText] = useState({});
  const fetchAllData = async () => {
    const res = await getFromAirtable("Intro Page Text", "Grid view", {
      data: { data: [], field: "All" },
    });
    // convert [{section, text}] --> section: text
    const t = res.data.data.reduce(
      (obj, item) => ({ ...obj, [item.Section]: item.Text }),
      {}
    );
    setText(t);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <div>
      <Nav className="explore-nav" id="explore-nav-push">
        <Link
          className="level2-link"
          id="profile-button"
          activeClass="active"
          to="profile-select"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {
            // showSectionOnNavClicked("section-1");
          }}
        >
          <CgProfile />
        </Link>
        <Link
          className="level2-link"
          id="knowledge-gaps-button"
          activeClass="active"
          to="knowledge-gaps"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {
            // showSectionOnNavClicked("section-1");
          }}
        >
          <GiOpenBook />
        </Link>
        <Link
          className="level2-link"
          id="country-select-button"
          activeClass="active"
          to="country-select"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {}}
        >
          <FaGlobeEurope />
        </Link>

        <Link
          className="level2-link"
          id="past-use-button"
          activeClass="active"
          to="past-use"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {}}
        >
          <HiArchive />
        </Link>
        <Link
          className="level2-link"
          id="legal-grounds-button"
          activeClass="active"
          to="legal-basis"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {}}
        >
          <GoLaw />
        </Link>
        <Link
          className="level2-link"
          id="generic-manufacturers-button"
          activeClass="active"
          to="generic-manufacturers"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {}}
        >
          <FaClinicMedical />
        </Link>
        <Link
          className="level2-link"
          id="email-collection-button"
          activeClass="active"
          to="email-collection"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {}}
        >
          <HiOutlineMail />
        </Link>
        <Link
          className="level2-link"
          id="action-plan-button"
          activeClass="active"
          to="action-plan"
          spy={true}
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => {}}
        >
          <VscGithubAction />
        </Link>
      </Nav>

      <div className="top">
        <div className="h3-top-holder">
          <h1>{text.title}</h1>
        </div>
      </div>
      <div className="bottom">
        <Container>
          <Row>
            <Col className="column1">
              <FadeIn delay="500" transitionDuration="1000">
                <div>
                  <p>{text["step 1"]}</p>
                  <Row>
                    <Col>
                      <Popover2
                        content={
                          <div className="modal-content">
                            <h1 className="title">Choosing Your Profile</h1>
                            <div className="generic-contents">
                              {profileContents}
                            </div>
                          </div>
                        }
                        enforceFocus={false}
                      >
                        <div className="about-button">
                          <h5>Profile</h5>
                          <CgProfile style={{ fontSize: 32 }} />
                        </div>
                      </Popover2>
                    </Col>
                  </Row>
                </div>
              </FadeIn>
            </Col>

            <Col className="column2">
              <FadeIn delay="1000" transitionDuration="1000">
                <div>
                  <p>{text["step 2"]}</p>
                  <Row>
                    <Col>
                      <Popover2
                        content={
                          <div className="modal-content">
                            <h1 className="title">Recieve Resources</h1>
                            <div className="generic-contents">
                              {dataResourcesContents}
                            </div>
                          </div>
                        }
                      >
                        <div className="about-button">
                          <h5>Data Resources</h5>
                          <GoLaw style={{ fontSize: 32 }} />
                        </div>
                      </Popover2>
                    </Col>
                  </Row>
                </div>
              </FadeIn>
            </Col>

            <Col className="column3">
              <FadeIn delay="1500" transitionDuration="1000">
                <div>
                  <p>{text["step 3"]}</p>
                  <Row>
                    <Col>
                      <Popover2
                        minimal={false}
                        modifiers={{ arrow: { enabled: true } }}
                        content={
                          <div className="modal-content">
                            <h1 className="title">Receive Your Action Plan</h1>
                            <div className="generic-contents">
                              {actionContents}
                            </div>
                          </div>
                        }
                      >
                        <div className="about-button">
                          <h5>Action Plan</h5>
                          <VscGithubAction style={{ fontSize: 32 }} />
                        </div>
                      </Popover2>
                    </Col>
                  </Row>
                </div>
              </FadeIn>
            </Col>
          </Row>
        </Container>
        <FadeIn delay="2100" transitionDuration="1200">
          <Link
            className=""
            activeClass="active"
            to="profile-select"
            spy={true}
            smooth={true}
            offset={-50}
            duration={1000}
            onClick={(e) => {
              setNextButtonClicked(true);
              document
                .getElementById("profile-select")
                .classList.remove("hide");
            }}
          >
            <div className="circle intro-circle">
              <AiOutlineLike size="55px" />
            </div>
            <p>{text["button 1"]}</p>
          </Link>
        </FadeIn>
      </div>
      <div id="profile-select">{nextButtonClicked && <ProfileSelect />}</div>
    </div>
  );
}

export default Intro;
