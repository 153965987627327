import React, { useState, useEffect } from "react";
import "./FAQS.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-scroll";
import SimpleAccordion from "../../../../components/accordion/accordion";

function FAQS(props) {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");
  const [FAQS, setFAQS] = useState([]);
  function getData() {
    return new Promise((resolve, reject) => {
      let faq = [];
      base("FAQs Page Text")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              let obj = {
                title: record.fields["Question"],
                description: record.fields["Answer"],
              };
              faq.push(obj);
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return reject({});
            }
            let result = {
              data: faq,
            };
            resolve(result);
          }
        );
    });
  }
  const fetchAllData = async () => {
    const res = await getData();
    console.log("res.data about text: ", res.data);
    setFAQS(res.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const faqs = [
    {
      title: "What are TRIPS Flexibilities?",
      description:
        "TRIPS flexibilities are ‘policy spaces’ for countries to mitigate the impact of patents (i.e., the excessively high price of patented medicines due to lack of competition fueled by monopolies). Acknowledged in the original text of the TRIPS agreement—particularly Article 30 (Exceptions) and Article 31 (Licences)—they were confirmed and given more detail and legal weight in the subsequent Doha Declaration on TRIPS Flexibilities and Public Health of 2001. Alongside other IP-related tools, TRIPS flexibilities are an effective tool to promote and achieve public health goals, such as affordable access to life-saving medicines. They also include provisions for accessing protected clinical data  which is crucial for producing and regulatory approval for generic and biosimilar medicines. The threshold of IP protection currently in force in the EU is higher than that laid out by the TRIPS Agreement and it makes extremely difficult for Member States to make use of flexiblites. (<a href='https://haiweb.org/wp-content/uploads/2019/06/HAI-TRIPS-Brochure-1.pdf'>source</a>)",
    },
    {
      title: "What is the TRIPS Agreement?",
      description:
        "The Trade-related Aspects of Intellectual Property (TRIPS) Agreement, constitutes a set of rules, principles and goals seeking to harness the protection and enforcement  of intellectual property (IP) within the advancement of international trade and economic globalisation. Prior to the introduction of the <a href='https://www.wto.org/english/tratop_e/trips_e/ta_modules_e.htm'>TRIPS Agreement</a>, an international agreement  ratified  in  1994 and part of the founding legal framework of the World Trade Organization (WTO), many low-, middle, and even high-income countries had excluded pharmaceutical products and/or processes from patenting  as part of public health safeguards  and domestic industrial promotion policies. With the introduction of the TRIPS Agreement, the international community agreed, for the first time, a multilateral covenant to set common standards for IP protection across all technological and industrial fields. (<a href='https://haiweb.org/wp-content/uploads/2019/06/HAI-TRIPS-Brochure-1.pdf'>source</a>)",
    },
    {
      title: "What is the issue with patents?",
      description:
        "The patent system is a social policy tool that is intended to encourage, stimulate and reward innovation. Governments grant innovators a patent as an acknowledgement of the added-value of their innovations. Society should be the main beneficiary from these innovations. However, the system, particularly within the area of pharmaceutical innovation, is currently out of balance and out of touch from goals and needs. It provides excessive (financial) rewards to patent holders (mostly large pharmaceutical companies) while inflicting huge costs for society to access and benefit. Those who cannot afford to pay-innovative or newly marketed medicines are de facto excluded from the benefits they would bring. (<a href='http://accesstomedicines.org/understanding-the-patent-system'>source</a>)",
    },

    {
      title: "Do patents always create monopolies?",
      description:
        "Not necessarily . When you hold a patent you are entitled to exclusive rights for the exploitation, development and related activities. You can, however,  delegate the right to use it to others (either in an exclusive or non-exclusive basis). This is called licensing or voluntary licensing. For example, when you have a patent on a medicine, you can grant another manufacturer, like a generic manufacturer, a licence to produce it. Often the generic manufacturer (licensee) will agree to pay a royalty or fee (usually based on an agreed percentage of the sales of the product) to the patent holder. Licences that allow for the production of a medicine can in turn be negotiated with multiple generic companies. This creates a competitive market, even though a patent has been granted for the medicine. The Medicines Patent Pool (MPP), which was established in 2010, is an example of an international voluntary licensing mechanism. It promotes licensing agreements for patents on AIDS, Malaria and Hepatitis medicines, and has recently widened scope to COVID-19 therapeutics and vaccines. The MPP also maintains the <a href='https://medicinespatentpool.org/what-we-do/medspal/'>MEDsPAL patent database</a>. (<a href='http://accesstomedicines.org/patents-and-monopolies'>source</a>)",
    },

    {
      title:
        "Isn’t an emergency situation needed before a government can issue a compulsory licence?",
      description:
        "This is a very common misunderstanding and not true. If a government is faced with an emergency, it can issue a compulsory licence without first trying to seek a voluntary licence. This makes sense because negotiating a voluntary licence can take time and, in an emergency, there is no time to waste. A compulsory licence in an emergency situation has slightly easier rules and speedier process, but an emergency is not a condition for issuing a compulsory licence. Governments are free to determine what constitutes an emergency situation. For example, many governments have referred to the HIV epidemic in their countries as an emergency situation and allowed the purchase and importation of generic medicines. (<a href='http://accesstomedicines.org/myths-and-realities'>source</a>)",
    },
    {
      title: "When have TRIPS flexibilities been used?",
      description:
        "Since 2001, compulsory licensing of medicines has been used 34 times in 24 countries. Meanwhile, there have been 51 instances of government use of patents by 35 countries, with a peak between 2004 and 2008. Between 2011 and 2016, there were approximately 100 instances of compulsory or government use of these tools, with 81 being implemented. While most were used to accelerate access to antiretrovirals (ARVs), a growing number of countries are turning to the use of TRIPS flexibilities to facilitate access to treatments for non-communicable diseases, such as cancers and cardiovascular diseases. Although originally focused on HIV, 23 out of 85 compulsory licence and government use instances have concerned non-HIV medicines. This includes seven licences or uses for cancer medicines between 2008 and 2014, of which five were granted. See this heatmap for specific use of TRIPS flexibilities in Europe. (<a href='https://haiweb.org/wp-content/uploads/2019/06/HAI-TRIPS-Brochure-1.pdf'>source</a>)",
    },
  ];

  function showSectionOnNavClicked(sectionName, setActive) {
    let showSection = document.getElementById(sectionName);
    let currentShow = document.getElementsByClassName("show");
    let active = document.getElementsByClassName("active");

    for (const element of currentShow) {
      element.classList.remove("show");
      element.classList.add("hide");
    }
    for (const item of active) {
      item.classList.remove("active");
    }

    showSection.classList.remove("hide");
    showSection.classList.add("show");
    setActive.add("active");
  }

  return (
    <>
      <div>
        <div className="top">
          <div className="h3-top-holder">
            <h1>Frequently Asked Questions About TRIPS Flexibilities </h1>
          </div>
        </div>
        <div className="bottom">
          <div className="faq-section">
            <SimpleAccordion accordionContent={FAQS} />
          </div>
          <div id="back-button">
            <Link
              id="back-circle"
              onClick={(e) =>
                showSectionOnNavClicked("welcome", e.target.classList)
              }
              to=""
            >
              <div className="circle">
                <IoMdArrowRoundBack style={{ fontSize: 55 }} />
              </div>
              <p>Go Back</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQS;
