import React, { useState, useEffect } from "react";
import ScrollIntoView from "react-scroll-into-view";
import { Button } from "react-bootstrap";
import { FiArrowDownCircle } from "react-icons/fi";
import "./products.css";
import SearchSelect from "../autocomplete/autocomplete";
import { Typography, Box } from "@mui/material";
import CountrySelect from "../country-select/country-select";
import TextMobileStepper from "../../../../components/carousel/carousel";
import { getFromAirtable } from "../../../../components/utils";

function Products(props) {
  // const sectionTitle = "section-" + String(props.sectionNum);
  const [nextClicked, setNextClicked] = useState(false);
  // want to get all the records of this product

  const [products, setProducts] = useState([]);
  const [allData, setAllData] = useState([]);
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey:
      "pattv1t4vOgRh6jbK.0194d9e4e7b0e6daaa0c699ceaef0898181e45d380b9013d925278d7ba2f503b",
  }).base("appltsiPqq0LjeIxI");

  function getData() {
    const data = [];
    const prods = [];
    return new Promise((resolve, reject) => {
      base("Main Data")
        .select({
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach(function (record) {
              data.push(record.fields);
              if (record.fields.Product) {
                const prodSplit = record.fields.Product.split(", ");
                prods.push(...prodSplit);
              }
            });
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              return reject({});
            }
            let result = {
              data: data,
              productsList: prods,
            };
            resolve(result);
          }
        );
    });
  }

  const [text, setText] = useState({});
  const fetchAllData = async () => {
    const prodText = await getFromAirtable("Products Page Text", "Grid view", {
      data: { data: [], field: "All" },
    });
    // convert [{section, text}] --> section: text
    const t = prodText.data.data.reduce(
      (obj, item) => ({ ...obj, [item.Section]: item.Text }),
      {}
    );
    setText(t);

    // get products
    const res = await getData();

    setAllData(res.data);
    setProducts([...new Set(res.productsList)]);
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const [selectedProduct, setSelectedProduct] = useState("");

  const [firstCarousel, setFirstCarousel] = useState([]);

  const onSelectChanged = (e, newVal) => {
    setSelectedProduct(newVal);
    const car = allData.map((rec) => {
      if (rec.Product?.includes(newVal)) {
        const des = Object.keys(rec).map((field) => {
          return { title: field, content: rec[field] };
        });
        return {
          label: newVal,
          description: des.filter((i) => i && i.title !== "EU"),
        };
      }
    });

    setFirstCarousel(car.filter((r) => r));
  };

  return (
    <div id="products">
      <div className="top">
        <div className="h3-top-holder">
          <h1>{text.title}</h1>
        </div>
      </div>
      <div className="bottom">
        <div>
          <h4>{text["select"]}</h4>

          <Box>
            <SearchSelect
              sx={{ margin: "auto" }}
              value={selectedProduct}
              options={products}
              searchLabel="Products"
              onChange={(e, newVal) => onSelectChanged(e, newVal)}
            />

            <Typography
              variant="h6"
              sx={{
                display: selectedProduct !== "" ? "none" : "block",
                mt: 10,
              }}
            >
              {text["button"]}
            </Typography>
            <ScrollIntoView
              selector="#products-result"
              style={{
                display: selectedProduct !== "" ? "none" : "block",
              }}
              onClick={(e) => null}
              alignToTop={true}
            >
              <Button
                style={{
                  height: 30,
                  backgroundColor: "#6AC5DC",
                  fontSize: 15,
                  color: "white",
                  padding: 5,
                  marginTop: 4,
                }}
                onClick={(e) => {
                  setNextClicked(true);
                }}
              >
                Skip this step
                <FiArrowDownCircle />
              </Button>
            </ScrollIntoView>

            <div className="products-carousel">
              {Object.keys(firstCarousel).length > 0 && (
                <TextMobileStepper steps={firstCarousel} />
              )}
            </div>
          </Box>
        </div>

        <ScrollIntoView
          selector="#products-result"
          style={{ display: selectedProduct !== "" ? "block" : "none" }}
          onClick={(e) => null}
          alignToTop={true}
        >
          <Button
            style={{
              height: 30,
              backgroundColor: "#6AC5DC",
              fontSize: 15,
              color: "white",
              padding: 5,
              marginTop: 40,
            }}
            onClick={(e) => {
              setNextClicked(true);
            }}
          >
            What are the grounds for applying for a compulsory license?
            <FiArrowDownCircle />
          </Button>
        </ScrollIntoView>
      </div>
      {nextClicked && (
        <div id="products-result">
          <CountrySelect profileName={props.profileName} sectionNum={4} />
        </div>
      )}
    </div>
  );
}

export default Products;
