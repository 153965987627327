import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import { AiOutlineQuestion } from "react-icons/ai";
import "./legal-basis.css";
import GmTransition from "../gm-transition/gm-transition";
import PressurePoints from "../pressure-points/pressure-points";
import TextMobileStepper from "../../../../components/carousel/carousel";
import { Dialog, Typography, Button as MuiButton } from "@mui/material";
import AirtableForm from "../../../../components/form/form";
import { Button } from "react-bootstrap";

function LegalBasis(props) {
  const [nextClicked, setNextClicked] = useState(false);
  const [multiRecord, setMultiRecord] = useState([]);
  const [firstCarousel, setFirstCarousel] = useState([]);

  function setMulti() {
    if (props.selectedRecord["Disease"].split(",").length > 0) {
      let multi = [];
      for (
        let i = 0;
        i < props.selectedRecord["Disease"].split(",").length;
        i++
      ) {
        multi.push({
          Disease: props.selectedRecord["Disease"]
            ? props.selectedRecord["Disease"].split(",")[i]
            : "N/A",
          Flexibility_type: props.selectedRecord["Flexibility_type"]
            ? props.selectedRecord["Flexibility_type"].split(",")[i]
            : "N/A",
          Product: props.selectedRecord["Product"]
            ? props.selectedRecord["Product"].split(",")[i]
            : "N/A",
          Generic_manufacturer_name: props.selectedRecord[
            "Generic_manufacturer_name"
          ]
            ? props.selectedRecord["Generic_manufacturer_name"].split(",")[i]
            : "N/A",
          Executed: props.selectedRecord["Executed"]
            ? props.selectedRecord["Executed"].split(",")[i]
            : "N/A",
        });
      }
      console.log("MULTI: ", multi);
      const listEls = [];
      for (const item of multi) {
        let obj = {};
        obj["label"] = "Trips Flexibilities Use in " + props.countryName;
        let des = [];
        for (const key of Object.keys(item)) {
          let o = {};
          if (item[key]) {
            o["title"] = key;
            o["content"] = item[key];
            des.push(o);
          }
        }
        obj["description"] = des;

        listEls.push(obj);
      }

      setMultiRecord(multi);
      setFirstCarousel(listEls);
    }
  }

  useEffect(() => {
    if (props.selectedRecord["Past_use"] === "true") {
      setMulti();
    }
  }, []);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);

  const gmForm = [
    { label: "Name", placeholder: "Name" },
    { label: "URL", placeholder: "Link" },
    { label: "Type", placeholder: "Opinion, Consultation, White Paper, etc." },
  ];

  return (
    <div id="legal-basis">
      {props.selectedRecord["Past_use"] === "true" && (
        <div>
          <div className="top">
            <div className="h3-top-holder">
              <h1>
                TRIPS Flexibilities have been used before in {props.countryName}
                !
              </h1>
            </div>
          </div>
          <div className="bottom">
            <Row className="bottom-content">
              <Col className="legislation-info2">
                <p>
                  The legislation that is associated with applying for a
                  complusory license in {props.countryName} is{" "}
                  <strong>{props.selectedRecord["Legislation_name"]}</strong>.
                  <br></br>
                  <br></br>
                  <br></br>
                  {/* <ReactMarkdown>
                {props.selectedRecord["License_grounds"]}
              </ReactMarkdown> */}
                  You can find more information about the legal basis and the
                  grounds for applying for a license in {props.countryName}{" "}
                  <a
                    href={props.selectedRecord["Legislation_link"]}
                    target="_blank"
                  >
                    here.
                  </a>
                </p>
                <p>
                  Know of other important legislation regarding compulsory
                  licenses? Click{" "}
                  <MuiButton onClick={handleClickOpen} variant="text">
                    here
                  </MuiButton>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    sx={{ minWidth: 900, minHeight: 700 }}
                  >
                    <div className="gm-dialog">
                      <Typography variant="h4" sx={{ mt: 2, ml: 2 }}>
                        Add a Resource
                      </Typography>
                      <div className="airtable-email-form">
                        <div id="gm-form">
                          <AirtableForm formFields={gmForm} />
                          <Button onClick={() => {}}>Submit</Button>
                        </div>
                        <div id="form-submitted">
                          <p>Thank you for submitting the form!</p>
                          <p>
                            Have more information? Click here to fill out the
                            form again!
                          </p>
                          <Button
                            onClick={() => {
                              // hide the thank you
                              document.getElementById(
                                "form-submitted"
                              ).style.display = "none";
                              // show the form
                              document.getElementById("gm-form").style.display =
                                "block";
                            }}
                          >
                            Redo
                          </Button>
                        </div>
                      </div>
                      <Button onClick={handleClose}>Close</Button>
                    </div>
                  </Dialog>{" "}
                  to tell us about them.
                </p>
              </Col>
              <div className="vl"></div>
              <Col className="legislation-info2">
                <div style={{ height: 400 }}>
                  {Object.keys(firstCarousel).length > 0 && (
                    <TextMobileStepper steps={firstCarousel} />
                  )}
                </div>
              </Col>
            </Row>
            <div id="gen-man-btn">
              <Link
                className=""
                id="gnmf"
                activeClass="active"
                to="legal-basis-result"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                onClick={() => {
                  setNextClicked(true);
                }}
              >
                <div className="circle">
                  <AiOutlineQuestion size="55px" />
                </div>
                <p>What comes next?</p>
              </Link>
            </div>
          </div>
        </div>
      )}
      {props.selectedRecord["Past_use"] === "false" && (
        <>
          <div className="top">
            <div className="h3-top-holder">
              <h1>
                There is a legal basis for TRIPS Flexibilities in{" "}
                {props.countryName}, although compulsory licenses have not been
                used before.
              </h1>
            </div>
            {/* <GoLaw className="header-icon" /> */}
          </div>
          <div className="bottom">
            <Row className="bottom-content">
              <Col className="legislation-info2">
                <p>
                  The legislation that is associated with applying for a
                  compulsory license in {props.countryName} is{" "}
                  <strong>{props.selectedRecord["Legislation_name"]}</strong>.
                  <br></br>
                  <br></br>
                  <br></br>
                  You can find more information about the legal basis and the
                  grounds for applying for a license in {props.countryName}{" "}
                  <a
                    href={props.selectedRecord["Legislation_link"]}
                    target="_blank"
                  >
                    here.
                  </a>
                </p>
                <p>
                  Know of other important legislation regarding compulsory
                  licenses? Click{" "}
                  <MuiButton onClick={handleClickOpen} variant="text">
                    here
                  </MuiButton>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    sx={{ minWidth: 900, minHeight: 700 }}
                  >
                    <div className="gm-dialog">
                      <Typography variant="h4" sx={{ mt: 2, ml: 2 }}>
                        Add a Resource
                      </Typography>
                      <div className="airtable-email-form">
                        <div id="gm-form">
                          <AirtableForm formFields={gmForm} />
                          <Button
                            onClick={() => {
                              // const name =
                              //   document.getElementById(
                              //     "Manufacturer Name"
                              //   ).value;
                              // const link = document.getElementById("URL").value;
                              // const country =
                              //   document.getElementById("Country").value;
                              // addToAirtable(name, link, country);
                              // // hide the form
                              // document.getElementById("gm-form").style.display =
                              //   "none";
                              // // show the thank you screen
                              // document.getElementById(
                              //   "form-submitted"
                              // ).style.display = "block";
                              // handleClose();
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                        <div id="form-submitted">
                          <p>Thank you for submitting the form!</p>
                          <p>
                            Have more information? Click here to fill out the
                            form again!
                          </p>
                          <Button
                            onClick={() => {
                              // hide the thank you
                              document.getElementById(
                                "form-submitted"
                              ).style.display = "none";
                              // show the form
                              document.getElementById("gm-form").style.display =
                                "block";
                            }}
                          >
                            Redo
                          </Button>
                        </div>
                      </div>
                      <Button onClick={handleClose}>Close</Button>
                    </div>
                  </Dialog>{" "}
                  to tell us about them.
                </p>
              </Col>
              <div className="vl"></div>
              <Col className="legislation-info2">
                <p>
                  Although compulsory licenses have not been used in{" "}
                  {props.countryName}, they have been used in other nations in
                  Europe including Albania, Germany, Italy, Romania, and
                  Switzerland.
                </p>
                <p>
                  Click{" "}
                  <a href="https://haiweb.org/wp-content/uploads/2019/06/HAI-TRIPS-Brochure-1.pdf">
                    here
                  </a>{" "}
                  to learn more about how and why TRIPS Flexibilities should be
                  used.
                </p>
              </Col>
            </Row>
            <div id="gen-man-btn">
              <Link
                className=""
                id="gnmf"
                activeClass="active"
                to="legal-basis-result"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                onClick={() => {
                  setNextClicked(true);
                }}
              >
                <div className="circle">
                  <AiOutlineQuestion size="55px" />
                </div>
                <p>What comes next?</p>
              </Link>
            </div>
          </div>
        </>
      )}
      <div id="legal-basis-result">
        {nextClicked && props.profileName === "Advocate" && (
          <PressurePoints
            profileName={props.profileName}
            selectedRecord={props.selectedRecord}
            countryName={props.countryName}
            sectionNum={6}
            tripsCountries={props.tripsCountries}
            tripsOriginators={props.tripsOriginators}
            tripsConditions={props.tripsConditions}
          />
        )}
        {nextClicked && props.profileName !== "Advocate" && (
          <GmTransition
            profileName={props.profileName}
            selectedRecord={props.selectedRecord}
            countryName={props.countryName}
            sectionNum={6}
            tripsCountries={props.tripsCountries}
            tripsOriginators={props.tripsOriginators}
            tripsConditions={props.tripsConditions}
          />
        )}
      </div>
    </div>
  );
}

export default LegalBasis;
